import React, { Component } from 'react'

export default class Preloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: "d-block"
    };
  }
componentDidMount()
{
    setInterval(() => {
        this.setState({done: "d-none"})
    }, 1000);
}
    render() {
        return (
            <div id="preloader" className={this.state.done}>
            <div className="loader1">
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        )
    }
}
