import Header from './component/Header';
import Index from './pages/Index';
import BlogPost from './pages/BlogPost';
import './index.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Articles from './pages/Articles';
import Newsletter from './pages/Newsletter';
import WebPageCopy from './pages/WebPageCopy';
import Whitepaper from './pages/Whitepaper';
import ProductDescription from './pages/ProductDescription'
import ExecutiveSummary from './pages/ExecutiveSummary'
import Businessplans from './pages/Buisnessplan'
import reports from './pages/Reports'
import presentation from './pages/Presentation'
import proposal from './pages/Proposal'
import corporate from './pages/Corporate'
import press from './pages/Press'
import ProductReview from './pages/ProductReview';
import Resume from './pages/Resume';
import InfoGraphic from './pages/InfoGraphic';
import Ebook from './pages/Ebook';
import ProofReading from './pages/ProofReading';
import brochure from './pages/Brochure'
import Videoscript from './pages/VideoScript'
import ScrollToTop from './component/ScrollToTop';
import Order from './pages/Order';

function App() {
  return (
    <>
      
      <Router>
      <ScrollToTop/>
        <Switch>
          <Route exact={true} path="/" component={Index}>
          </Route>
          <Route exact={true} path="/blogpost" component={BlogPost}>
          </Route>
          <Route exact={true} path="/articles" component={Articles} />
          <Route exact={true} path="/newsletter" component={Newsletter} />
          <Route exact={true} path="/webcopywriting" component={WebPageCopy} />
          <Route exact={true} path="/whitepapers" component={Whitepaper} />
          <Route exact={true} path="/productdescription" component={ProductDescription} />
          <Route exact={true} path="/executivesumary" component={ExecutiveSummary} />
          <Route exact={true} path="/businessplan" component={Businessplans} />
          <Route exact={true} path="/reports" component={reports} />
          <Route exact={true} path="/presentation" component={presentation} />
          <Route exact={true} path="/proposal" component={proposal} />
          <Route exact={true} path="/press" component={press} />
          <Route exact={true} path="/corporate" component={corporate}/>
          <Route exact={true} path="/productreviews" component={ProductReview}/>
          <Route exact={true} path="/resumewriting" component={Resume}/>
          <Route exact={true} path="/infographs" component={InfoGraphic}/>
          <Route exact={true} path="/ebook-writing" component={Ebook}/>
          <Route exact={true} path="/proofreading" component={ProofReading}/>
          <Route exact={true} path="/brochure" component={brochure}/>
          <Route exact={true} path="/videoscript" component={Videoscript}/>
          <Route exact={true} path="/ordernow" component={Order}/>
        </Switch>
      </Router>
    </>
  );
}

export default App;
