import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
export default class PriceCalculator extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            topic:"",
            page:[],
            words:[],
            time: [],
            pagevalue:"",
            // wordnum:"",
            duration:"",
            totalamount:0
        }

    }

    appearpage = (topic) =>
    {
        this.setState({topic});
        //loop for page
        for(var i = 1; i<=200; i++){
            const p = this.state.page
            p.push(i);
        }
        // words
            const w = this.state.words
            w.push(100,200,300,400,500,600,700,800,900,1000);
        // time duration
            const t = this.state.time
            t.push("Standard","Urgent")
    }

    selectpage = () => 
    {
        let page = this.state.pagevalue;

        axios.get("http://api.currencylayer.com/live?access_key=dc80acaba5a4db9e491fa1f027013b20")
        .then((response)=>
        {
            const exchangerate = response.data.quotes.USDPKR
             console.log(exchangerate)
             let wordscount = parseInt(page) * 275;
             let priceperunit =  1 / parseInt(exchangerate) ;
             let unit = priceperunit * 10
             let result = unit * wordscount
             let urgent = this.state.duration;

             if(urgent == "Standard")
             {
                    this.setState({
                        totalamount : Math.round(result)
                    })
             }else{
                    this.setState({
                        totalamount : Math.round(2 * result)
                    })
             }


             console.log(result); 
        })
        
        
        // this.setState({
        //     totalamount: page * price
        // })
        




    
    }
    render() {
        let {topic , page  } = this.state
        return (
            <>
            <section className="call-to-action-video">
                <div className="row m-0">
                <div className="col-lg-6 col-md-12 p-0">
                    <div className="testimonial-content-wrap">
                        <img src="img/testimonial-arrow-top.png" className="img-fluid testimonial-tb-shape shape-top" alt="testimonial shape" />
                        <OwlCarousel loop items={1} className="owl-carousel owl-theme client-testimonial-1 custom-dot testimonial-shape">
                            <div className="item">
                                <div className="testimonial-quote-wrap">
                                    <div className="media author-info mb-3">
                                        <div className="author-img mr-3">
                                            <img src="img/client-1.jpg" alt="client" className="img-fluid" />
                                        </div>
                                        <div className="media-body text-white">
                                            <h5 className="mb-0 text-white">John Charles</h5>
                                            <span>BizBite</span>
                                        </div>
                                        <span className="fas fa-quote-right icon-md text-white" />
                                    </div>
                                    <div className="client-say text-white">
                                        <p>
                                        Being in the business for customer overhauling we need to grow an excess of substance excessively fast, Buzinesswriting has consistently been there to convey their administrations and has consistently prevailed upon us. Much thanks to you Buzinesswriting for your persevering administrations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-quote-wrap">
                                    <div className="media author-info mb-3">
                                        <div className="author-img mr-3">
                                            <img src="img/client-2.jpg" alt="client" className="img-fluid" />
                                        </div>
                                        <div className="media-body text-white">
                                            <h5 className="mb-0 text-white">Arabella Ora</h5>
                                            <span>BizBite</span>
                                        </div>
                                        <span className="fas fa-quote-right icon-md text-white" />
                                    </div>
                                    <div className="client-say text-white">
                                        <p>
                                        They truly comprehended what I needed as far as my web content system. Their utilization of straightforward yet convincing English left me pleased profoundly. Caps off to the innovative delivering their administrations at Buzinesswriting.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-quote-wrap">
                                    <div className="media author-info mb-3">
                                        <div className="author-img mr-3">
                                            <img src="img/client-3.jpg" alt="client" className="img-fluid" />
                                        </div>
                                        <div className="media-body text-white">
                                            <h5 className="mb-0 text-white">Jeremy Jere</h5>
                                            <span>BizBite</span>
                                        </div>
                                        <span className="fas fa-quote-right icon-md text-white" />
                                    </div>
                                    <div className="client-say text-white">
                                        <p>
                                        Working with Buzinesswriting was a joy, we're very time the board zeroed in thus getting speedy reactions with top-class content created has been an incredible encounter. Anticipating a commonly helpful connection with them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-quote-wrap">
                                    <div className="media author-info mb-3">
                                        <div className="author-img mr-3">
                                            <img src="img/client-4.jpg" alt="client" className="img-fluid" />
                                        </div>
                                        <div className="media-body text-white">
                                            <h5 className="mb-0 text-white">John Charles</h5>
                                            <span>BizBite</span>
                                        </div>
                                        <span className="fas fa-quote-right icon-md text-white" />
                                    </div>
                                    <div className="client-say text-white">
                                        <p>
                                        Consistent Content doesn't distribute client tributes, yet as per the site, "Quality is vital and greatness is ensured. Consistent Content doesn't bargain in content that is normal or shoddy. We just sell the greatest, most unique substance."
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel >

                        <img src="img/testimonial-arrow-bottom.png" className="img-fluid testimonial-tb-shape shape-bottom" alt="testimonial shape" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 p-0">
                    <div className="card login-signup-card shadow-lg mb-5">
                        <div className="card-body px-md-5 py-5">
                            <div className="mb-5">
                                <h6 className="h3">Price Calculator</h6>
                                <p className="text-muted mb-0">Get a statement on your necessity – on the spot</p>
                            </div>
                            <form className="login-signup-form">
                                <div className="form-group">
                                    {/* Label */}
                                    <label className="pb-1">
                                        Select The Service Required
                                    </label>
                                    {/* Input group */}
                                    <div className="input-group input-group-merge">
                                        <select className="form-control" onChange={(e) => this.appearpage(e.target.value) }>
                                        <option value="">Select Topic</option>
                                            <optgroup label="Blogs & Articles">
                                                <option >Blog Post</option>
                                                <option >Articles</option>
                                                <option >Newsletter Content</option>
                                            </optgroup>
                                            <optgroup label="Web Content">
                                                <option >Webpage Copy</option>
                                                <option >White Paper</option>
                                                <option >Product Description</option>
                                            </optgroup>
                                            <optgroup label="Business Writing">
                                                <option >Executive Summaries</option> 
                                                <option >Business Plans</option> 
                                                <option >Report/Case Studies</option> 
                                                <option >Presentation PPT</option> 
                                                <option >Proposals</option> 
                                                <option >Corporate Profiles</option> 
                                            </optgroup>
                                            <option >Press Release</option>
                                            <optgroup label="Seo Writing">
                                                <option >Blog Post</option> 
                                                <option >Articles</option> 
                                                <option >Newsletter Content</option> 
                                                <option >Press Release</option> 
                                                <option >Product Reviews</option> 
                                            </optgroup> 
                                            <option >Resume Writing</option>
                                            <optgroup label="Others">
                                                <option >Info Graphs</option> 
                                                <option >Ebook</option> 
                                                <option >Editing / Proof Reading</option> 
                                                <option >Brochure Content Writing</option> 
                                                <option >Video Script Writing</option> 
                                            </optgroup> 

                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        {/* Label */}
                                        <label className="pb-1">
                                            Page(s) of Product Required:
                                        </label>
                                        {/* Input group */}
                                        <div className="input-group input-group-merge">
                                            <select className="form-control" onChange={(e) => this.setState({pagevalue : e.target.value})}>
                                                <option value="">Select </option>
                                                {
                                                    this.state.page.map(key => {
                                                        return <option value={key}>{key} page</option>
                                                    })                              
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <label className="pb-1">
                                             Words per page:
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <select className="form-control" onChange={(e) => this.setState({wordnum : e.target.value})}>
                                                <option value="">Select </option>
                                                {
                                                    this.state.words.map(key => {
                                                        return <option value={key}>{key} words</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                {/* Password */}
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        {/* Label */}
                                        <label className="pb-1">
                                            Urgency
                                        </label>
                                        {/* Input group */}
                                        <div className="input-group input-group-merge">
                                            <select className="form-control" onChange={(e) => this.setState({duration:e.target.value})}>
                                                <option value="">Select </option>
                                                {
                                                    this.state.time.map(key => {
                                                        return <option>{key}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <h3 className="mt-3">${this.state.totalamount} USD </h3>
                                    </div>
                                    <div className="col-md-6">
                                                    {/* Submit */}
                                        <button type="button" onClick={this.selectpage} className="btn btn-block secondary-solid-btn border-radius mt-4 mb-3">
                                            Calculate
                                        </button>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            </section>
            {/*client section start*/}
            <div className="client-section ptb-100" style={{ background: 'url("img/client-bg.jpg")no-repeat center center / cover' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 text-center">
                                    <div className="client-heading-wrap">
                                        <h5>
                                        * The packages mentioned on this service are for standard projects. Your requests such as the difficulty, the industry, and quality may require bespoke pricing. To get a precise estimate, please call toll free and UK Whatsapp No   +44 742 912 5487
, U.S TOLL FREE +1 888 352 6601, start a live chat.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*client section end*/}
            </>
        )
    }
}
