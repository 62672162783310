import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer'
import Garuntee from '../component/Garuntee'
import Header from '../component/Header'
import Preloader from '../component/Preloader'
import PriceCalculator from '../component/PriceCalculator'
import Steps from '../component/Steps'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'
export default class Executivesummary extends Component {
    constructor() {
        super();
        this.state = {
            setIsOpen: false,
            setIsOpen2:false,
            setIsOpen3:false,
            setIsOpen4:false
        }
    }
    openModal() {
        this.setState({ setIsOpen: true })

    }
    openModal02() {
        this.setState({setIsOpen2:true})
    }
    openModal03() {
        this.setState({setIsOpen3:true})
    }
    openModal04() {
        this.setState({setIsOpen4:true})
    }
    closeModal() {
        this.setState({ setIsOpen: false })
        this.setState({ setIsOpen2: false })
        this.setState({ setIsOpen3: false })
        this.setState({ setIsOpen4: false })
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Buziness Writing | Executive Summary</title>
            </Helmet>
              {/* <Preloader/> */}
               <Header/>  
                {/*header section start*/}
               <section className="hero-section ptb-100 gradient-overlay" style={{ background: 'url("img/header-bg-5.jpg")no-repeat center center / cover' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10">
                                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                    <h1 className="text-white mb-0">SAY THE RIGHT THING WITH LESSER WORDS</h1>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn check-btn mt-3">Get Started</a>
                                    <h3 className="text-white mt-3">
                                    An extraordinary leader synopsis is one that makes the right articulation in simple words, conveying the correct message.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*header section end*/}

                {/*about section with promo start*/}
                <section className="about-with-promo ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <h2>EXECUTIVE SUMMARIES</h2>
                                    <span className="animate-border mb-4" />
                                    <p>
                                    Executive summaries are another important business documents that are considered vital for major business related decisions. Business plans come first, but executive summaries have their own significance. It's fair to say that almost every big decision inside any company of any size involved an executive summary.
                                    </p>
                                    <p>
                                    We have a group of exceptional expert and skilled scholars who have the right insights recorded as hard copy leader round ounces. They have the ideal ability to sharpen your image with the right choice of words.
                                    </p>
                                    <ul className="check-list-info">
                                        <li><strong>report/case subject

</strong> Well researched and focused on your </li>
                                        <li><strong>Formatted and structured </strong>as per your order specs</li>
                                      
                                        <li><strong>Quickest turnaround times  </strong> – within stipulated timelines</li>
                                        <li><strong>Error-free content </strong>– Checked by Editors</li>
                                        <li><strong>Keywords based</strong>on your subject area </li>
                                        <li><strong>online support</strong> Round the clock available

</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-promo-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/increase.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p >Document - 01</p>
                                                {/* <button >Click Me</button> */}
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal02()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/search-engine.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 02</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal03()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/credit-card.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 03</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal04()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/interface.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 04</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*about section with promo end*/}
               <PriceCalculator/>
                <Garuntee/>
                <Steps/>
                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>How Electric Skateboards Work</h2>
                            <p>
                            Skateboarding is one of the favorite hobbies of adults. Also in the USA people are growing fonder of skateboarding every passing day. In the following article, we are going to explain to our readers how actually do electric skateboards work.
Normally you have to kick start any skateboard, but when it comes to electric skateboards it is a bit different. Electric skateboards have a motor attached to it and thus is powered by it. Usually, the way to start riding an electric skateboard is to open up the throttle. The electric skateboards we are talking about not are the ones that have to be controlled by remote control. When you are riding the electric skateboard, through the remote you can control your speed or brake. If it comes to changing your direction while riding an electric skateboard, all you have to do is put your weight to the side of the skateboard where you want to turn. Generally, an electric skateboard is meant to have a speed of 15 mps to 30 mps and a weight of 22 lbs. The range of the electric skateboard really depends on certain factors like the motor power, terrain, or the weight of the rider. On average, this tool has a range of almost 10 miles to 25 miles. 
What is an electric skateboard exactly?
In the late 1990s, Louie Finkle invented the electric skateboards. As he was a great fan of skateboarding, he thought to combine both the experience and his love for skateboarding with technology. With the first invention, the electric keyboard has quite some of the flaws that had to be looked up to. As soon as time passed and technology got its way to advancement the electric skateboard also became flawless.
As we have talked about earlier that the difference between a push skateboard and an electric skateboard is that the electric skateboard moves by a motor. Whereas the push skateboard requires kick for keeping it in motion. The electric skateboard does not require any kind of effort to make it more as it is completely controlled by a remote. The motor, in short, does everything for you, which includes start, accelerate, and stop.
 How does an electric skateboard work exactly?
Even though the electric skateboards give you a complete ride with a thrill. But at the same time, one should know that how it exactly works, so that whoever rides an electric skateboard is careful while riding it.
As told above, electric skateboards have motors attached to it and thus are controlled by remotes. While the throttles are triggered on the remotes electromagnetic waves are sent to the skateboard, making it move. The electronic speed controller or the ESC gives away the command. The electric skateboard moves with the speed which is given from the remote to the motor. Whenever you want to turn in a different direction, you just have to put your weight on that side of the skateboard, and thus the direction would be changed.
Moving down we are going to tell you about the parts of an electric skateboard. In this manner, you will have knowledge about your electric skateboard and will even get to know how to repair it.
Basic components of an Electric Skateboard
A Deck
The deck is the most important part of the skateboard. The rider is meant to ride while standing on the deck. Usually, the decks of skateboards we see are made up of wood but some with better quality is made up of carbon and fiberglass so that the deck becomes strong and thick. The carbon and the fiberglass are used for the deck making it stronger, long-lasting, and tougher and so it is lightly weighted too

Trucks
This is one of the most important parts of an electric skateboard. Trucks are made out of metal and they are meant to send down the weight of the rider’s body to the deck and then to the wheels. Additionally, it assistances the rider to turn his directions while riding.  
The truck is made up of the bushings, the hanger, axel, and the kingpin. The truck is meant to assure that the electric skateboard gives out a quality performance to its riders.
Wheels
Wheels are very essential when combining all the components to make up an electric skateboard. Wheels vary according to every skateboard. They are usually made out of polyurethane but come up in many colors, sizes, and shapes. 
Big wheels are better for riding on rough and uneven surfaces it is because they roll easily and move fast. Smaller kinds of wheels have a fairly low center of gravity but do not need hard force for acceleration. Though the speed does get slower.
Grip Tape 
Grip tape is meant to be a two-sided tape, one side sticks to the deck while the surface of it acts as the surface of sandpaper. Through the grip tape, all of the force and energy of the rider moves from the feet to the deck and then to the wheels. This grip tape helps and supports the rider to keep his balance while riding, putting a brake, or stopping the electric skateboard.
Ball Bearings 
All the wheels of the electric skateboard are connected to the axel through two of the ball bearings. Usually, the ball bearings are made out of steel but some of the electronic skateboards can also have silicon nitride ball bearings. These ball bearing help the wheels in rotation. The maintenance is key, you have to clean and lubricate the ball bearings on a regular basis so that a good performance of the skateboard is not hindered. You can easily purchase the maintenance kits which will help you in maintaining the ball bearings. If not done so, then you will face disturbance regarding the skateboard on an everyday basis.  
Mechanical Parts
Motor 
The motor is very important for the electronic skateboards as it keeps it going. While there are two types of motors that are used in the electronic skateboards. Let us head to both types;
Hub Motor
The hub motor is fixed in the wheels so that wheels can get the power directly. Professional skateboarders choose the hub motors every time for their skateboards. The performance of the hub motor is always compared to the bell motor. The hub motors needless repairs and give riders better results.
The hub motors' performance can be affected due to the heat trap. There is a huge chance that the hub motor gets overheated as in the wheels there is no place for air to cross and keep it cool. Even though the casing of the motor has some holes so that less heat is trapped in the wheels but these holes in the motor can endanger the 
skateboard. These electric skateboards are not designed to be waterproof. So it is important for the rider to keep it away from wet surfaces.
Belt-Driven Motor
The belt-driven motor is the usual kind of motor that has been used in electric skateboards since forever now. The motor pulley is interlocked with the wheel pulley via a belt. This belt-driven motor is waterproof as there are no holes made in the case of the motor.
Battery 
The battery in the electronic skateboard helps to speed up the skateboard. The battery size of the electronic skateboard truly tells about the range of the skateboard. Bigger batteries attached to these can give you a large distance. If you want to know how long do batteries of an electric skateboard last for, you should know about the different types of batteries that are normally used in an electric skateboard. Mostly, there are two types of batteries that are used in electric skateboards. They are a Lead acid battery and a lithium-ion battery.
Lithium-ion Battery 
This battery is known to be the costly one but is very beneficial as it is a very long-lasting battery. This battery has an age of almost about 2 to 3 years if maintained properly. It gives a better range than that of its counterparts. It just required 5 to 6 hours to get fully charged.
Lead-Acid Battery 
The lead-acid battery is of a lesser price than that of a lithium-ion battery. It requires 12 to 24 hours to charge completely. Also, it has a short battery life of 9 to 10 months. It covers less distance as compared to the lithium-ion battery.
You should always have a good quality of battery in your skateboards so that you don’t face any unseeing incident. 
Remote Control 
With the help of the remote control, you can move your electronic skateboard wherever you want. You can either accelerate, speed up, or stop the skateboard. Different types of skateboards have different remote controls. Some of the electronic skateboards have a remote control with buttons that are rotatable. Here you can push that button the make the skateboard move and pull it to make it stop. While in the other type of skateboard models you can find a trigger, if you pull the trigger it starts to move where removing your hand from it will make it stop.
Electronic Speed Controller
It is known to be the brains of the electronic skateboard. All the decisions are taken here about what has to be done about how much power is to be transmitted from the battery to the motor. It gives away respective signals. 
The signals coming from the remote are transmitted to skateboard which tells the skateboard what to do and how much speed to apply. 
Bluetooth 
The Bluetooth technology has made it pretty easy for transmitting signals from the remote to the skateboards. This Bluetooth technology has brought up the skateboards to a more emerging level

                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen2}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>WordPress for Event Listings Website - Is It A Good Choice</h2>
                            <p>
                            For website development, you have a plenty of options available in the market. All of the available platforms are offering unique and customized features. One way is to develop a website from a scratch through coding. However, this may not be the smart move because you don't need to invent the wheel again rather use the existing one.
If you have an option to design and develop a website much quicker than coding all by yourself then you must go that option. So, leaving behind the self-development of the website we have still a lot of options available like Wix, Jhoomla and many more too. 
Among all of them, the one which is a very popular and recommended framework is the WordPress. Let's review quickly the reasons why WordPress is a smart choice.
Reasons why you should use WordPress to build your website
•	Easy to Use
WordPress is easy to learn and easy to use, even if you are totally unfamiliar with it right now, still you can create a website with a little effort. The important part is that a huge variety of themes is available free and paid both which can accommodate any type of designing needs. For functionalities concern, as for the event listing website, the users need specific types of features like registration, event listing, and event calendar and so on, there comes a handful of plugins in WordPress.
•	Free to Use
The most important concern, that WordPress is totally free with all its features. You have to pay nothing for the framework and you might be conscious about the fact that whether you will have a control over your website or not? Obviously, WordPress is just a framework enriched with a variety of options and features that you can customize as per your needs. You can transfer the website at any moment with all your data.
•	Keeps Growing
The best thing about the WordPress is that with every new day something new is coming and adding up the features either new themes are introduced or new plugins to add up the technical features etc. Due to the fact that WordPress keeps growing is what attracts the people and that is the main reason for its popularity. 
•	Variety of functionalities
Keeping the framework generic and lightweight the functionalities can be adopted through various plugins that perform particular functions. So, by that whatever feature you are looking for you do not need to develop it rather, you can just get a plugin either free version or paid to add particular features to your website. For an instance, if we are looking for a registration feature on the event listing website, we can get that through plugins. Along with many other plugins, WordPress Event Manager Plugin is offering a variety of add-ons that can add extra functionalities to your event listing website. 
You might be at times hosting a number of the event while using WordPress to give power to your current website, but you should also know that you can create an event listing website or convert the website made by WordPress to a complete event management portal just by using the event management plugin.
Whenever you start to create your event management website, there are going to be a number of features that you most likely will be willing to include while making your site so that it gives better performance and is more attractive. You would want to add features that must include forms for registration, aa form for submitting information of the vent, the number is ways that should be used to display and manage the complete information for the users and then sell its tickets, also there should be a calendar that should be appropriate and exact, while with WordPress plugin and more of it.
For further ado, all you have to do is install the plugin and there you can showcase and sell tickets that are meant to be for your upcoming event, not just that the WordPress plugin will help you in manage your whole events and then charge you accordingly for what it is meant to be. Furthermore, all the advanced options are meant to be in the add-ons which are going to let you add more, expanded features of your website like creating alerts, sending tickets, and helping users to search the events accurately location-wise, and a lot more.
With the WordPress Event Manager now you can create your very own WP events listing site using backend and frontend submission forms that are going to be used for your event, and don’t forget these frontend and backend is for free. 
All the people who are organizing their events can make their listing of events go live and they would then know how the listing looks when it is published. You should also know that there would be no difference in the preview or when you publish the listings. All of the organizers of the event can correct or edit their listings until the time they are satisfied with its look and publish it when they are ready to do so. This is how they will know that it works the way they wanted and without a doubt makes it look grand after it is published.
WordPress Event Manager Plugin
About the WP Event Manager Plugin, it is a scalable, lightweights yet a full-featured event manager plugin that is used for contributing for event listing to your very own WordPress website. The shortcodes it lists down have all the events mentioned, also it can be compatible and so can work with any of the themes, after which you can customize or setup any theme of your choice. The page of your settings helps you to select in what way you would like your event to be displayed. You should also know that you don't always have to spend money while doing event listing as WP comes in both forms of a free version and a paid version to be precise.
 The WP plugin includes a lot of sort codes that give the output of events in a number of formats, and as it is made up of Custom Post Types you can easily extend it furthermore to the themes. As told before the WP plugin is free and so it covers a number of functionalities that are considered core by us, and through which a simple event listing site can be created.  
Moreover, there are many other features present in the advanced functionality through the option of add-ons. These add-ons are very useful for the core plugin and 
also they help you to fund the support and development of the core.  This is not all as there are more Pro Add-ons plugin features that may interest you, they may include;
•	The calendar for displaying the event date for all the upcoming events. 
•	The google maps that may let you know the exact location of your event.
•	The registration allows the people to register for your events. 
•	The WooCommerce Sell Tickets are able to sell tickets live and so you can even keep a track of it. 
These might just be some as Pro Add-ons plugin features also include event alerts, bookmarks, embeddable event widget, sliders, organizers, contact organizer, email, event tags, google analytics, and logs. I know all these options might make you drool as it is a complete package.  
Conclusion
On the whole, the WP plugin is easy to install and is very easy to operate and so it will not at all give you a hard time while you are working on your website. Also if you are an event management agency or want to come up with an event management website then WordPress plugin is a full and final option for you that is at no cost going to let you down in what you want to do. It not just gives you options but gives you its best and complete features on the go while you save your time and so you don't have to look for any other options here and there apart from the WP plugin. With your chances, you can easily adapt to this website as it is very easy and will keep on helping you on its own and help you build up a website that is the most suitable for you.

                            </p>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpen3}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Video in Marketing</h2>
                            <p>
                            Day by day the technology is improving, yet diverse ways of doing things are also emerging. Whereas marketing is not just confined to billboards or newspapers, there are many improved ways of doing marketing in todays' world. The forward-facing strategy of marketing has introduced Video Marketing as a marketing campaign for engaging customers.
For the process of Video Marketing for attorneys, it is not an easy task to sell themselves, yet it can be very difficult for them to translate their expertise in words, this is where video marketing helps play a vital role. There are many ways to for these attorneys to use video marketing, some of them are as follows.  
1-	Videos help humanize your law firm 
Videos are being an online marketing strategy for lawyers too, they believe that before one should speak, there should be a personal connection through videos, a medium in which humans understand better. A law firm can with no trouble be promoted through videos on the internet, such videos help to educate clients and boost online rendezvous. Marketing through videos has been widely popular because it is believed that human beings can process visuals quicker than written text. Nowadays, people watch videos rather than reading their text to educate themselves about their law firm. A visual text is appreciated and helps the firm tell its story about itself in a better manner, moreover it assists to humanize the firm as the clients get to know it properly while having the comfort of their own home. 
2-	Information retention 
After researching, the fact came up that humans can retain information given to them through visual context better than the information provided through them in text form. Videos are administered 60,000 times faster in the human brain than a normal test, thus proving the fact that 90% of the information the human brain absorbs is through the visuals. In a recent report, it is highlighted that videos have a great influence on social platforms and search engines. It is also reported that video promotions are 600% more effective than print and text media combined. To hire an attorney is a tough job, an while going through profiles, one cannot easily retain every attorneys bio, here is when retention is very helpful, and videos are one of the few ways where a person doesn’t have to put effort to retain what they saw. 
3-	Your prospects do love video; and so does google
It is researched that an average person spends 88% more time on the websites having videos rather than the one which does not have videos. According to the new study, it is known that young people considerably watch more videos than adults. People aged from 13 till 24 are found to watch 12.1 hours of videos each week. Even Google and other social media platforms love videos, these videos are a technique to increase and improve rankings. Websites of attorneys also know that people are influenced by videos, so they make sure that their websites are stocked with videos that display their bar associations, the awards they have won over time, and also other credentials like the rating they have on their websites . The law firm should know that what their clients are in search of mostly so that they make sure that they acquire peoples’ desires. When people are satisfied and likely to visit their website, it will automatically bring it on an improved ranking on Google too. 
4-	People watch videos on mobile
Size of the screen as no longer be a concern for watching videos as it is recorded that more than half of the people are watching videos on cell phones. The videos are easier to watch and are easily fixed in the palm of your hand. It is easier for them to reach to the videos, then why not hit the same video forums with our videos? Legal Marketing on the same video forums will give our videos a boost and our marketing more value as these websites are known to be visited the most. 
5-	Video is proven to boost conversion and sales
If a picture is said to be a thousand words, then a video for a product is said to be worth a thousand sales. It should be considered that if there is a video of a product it is supposed to appear in 70% of the top 100 search list with results.  As 64 to 85% of people are likely to buy a product after watching the products' video, which surely increases conversions and sales of that product. Such people are more comfortable about making their decision because their visuals are much more firm than the content they read about the certain product. Law firms take advantage of it and enhance their hiring by making attractive videos which fulfill requirements of the various clients who are looking for attorneys.
While more attorneys are having websites, videos help them to stand out amongst the ongoing law firm competition. It helps establish trust in between the attorney and its potential client, moreover it makes the law firm more visible for those who are searching. 


                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen4}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Literature Analysis of Zoom by Robert Munsch </h2>
                            <h2>WordPress for Event Listings Website - Is It A Good Choice</h2>
                            <p>
                            For website development, you have a plenty of options available in the market. All of the available platforms are offering unique and customized features. One way is to develop a website from a scratch through coding. However, this may not be the smart move because you don't need to invent the wheel again rather use the existing one.
If you have an option to design and develop a website much quicker than coding all by yourself then you must go that option. So, leaving behind the self-development of the website we have still a lot of options available like Wix, Jhoomla and many more too. 
Among all of them, the one which is a very popular and recommended framework is the WordPress. Let's review quickly the reasons why WordPress is a smart choice.
Reasons why you should use WordPress to build your website
•	Easy to Use
WordPress is easy to learn and easy to use, even if you are totally unfamiliar with it right now, still you can create a website with a little effort. The important part is that a huge variety of themes is available free and paid both which can accommodate any type of designing needs. For functionalities concern, as for the event listing website, the users need specific types of features like registration, event listing, and event calendar and so on, there comes a handful of plugins in WordPress.
•	Free to Use
The most important concern, that WordPress is totally free with all its features. You have to pay nothing for the framework and you might be conscious about the fact that whether you will have a control over your website or not? Obviously, WordPress is just a framework enriched with a variety of options and features that you can customize as per your needs. You can transfer the website at any moment with all your data.
•	Keeps Growing
The best thing about the WordPress is that with every new day something new is coming and adding up the features either new themes are introduced or new plugins to add up the technical features etc. Due to the fact that WordPress keeps growing is what attracts the people and that is the main reason for its popularity. 
•	Variety of functionalities
Keeping the framework generic and lightweight the functionalities can be adopted through various plugins that perform particular functions. So, by that whatever feature you are looking for you do not need to develop it rather, you can just get a plugin either free version or paid to add particular features to your website. For an instance, if we are looking for a registration feature on the event listing website, we can get that through plugins. Along with many other plugins, WordPress Event Manager Plugin is offering a variety of add-ons that can add extra functionalities to your event listing website. 
You might be at times hosting a number of the event while using WordPress to give power to your current website, but you should also know that you can create an event listing website or convert the website made by WordPress to a complete event management portal just by using the event management plugin.
Whenever you start to create your event management website, there are going to be a number of features that you most likely will be willing to include while making your site so that it gives better performance and is more attractive. You would want to add features that must include forms for registration, aa form for submitting information of the vent, the number is ways that should be used to display and manage the complete information for the users and then sell its tickets, also there should be a calendar that should be appropriate and exact, while with WordPress plugin and more of it.
For further ado, all you have to do is install the plugin and there you can showcase and sell tickets that are meant to be for your upcoming event, not just that the WordPress plugin will help you in manage your whole events and then charge you accordingly for what it is meant to be. Furthermore, all the advanced options are meant to be in the add-ons which are going to let you add more, expanded features of your website like creating alerts, sending tickets, and helping users to search the events accurately location-wise, and a lot more.
With the WordPress Event Manager now you can create your very own WP events listing site using backend and frontend submission forms that are going to be used for your event, and don’t forget these frontend and backend is for free. 
All the people who are organizing their events can make their listing of events go live and they would then know how the listing looks when it is published. You should also know that there would be no difference in the preview or when you publish the listings. All of the organizers of the event can correct or edit their listings until the time they are satisfied with its look and publish it when they are ready to do so. This is how they will know that it works the way they wanted and without a doubt makes it look grand after it is published.
WordPress Event Manager Plugin
About the WP Event Manager Plugin, it is a scalable, lightweights yet a full-featured event manager plugin that is used for contributing for event listing to your very own WordPress website. The shortcodes it lists down have all the events mentioned, also it can be compatible and so can work with any of the themes, after which you can customize or setup any theme of your choice. The page of your settings helps you to select in what way you would like your event to be displayed. You should also know that you don't always have to spend money while doing event listing as WP comes in both forms of a free version and a paid version to be precise.
 The WP plugin includes a lot of sort codes that give the output of events in a number of formats, and as it is made up of Custom Post Types you can easily extend it furthermore to the themes. As told before the WP plugin is free and so it covers a number of functionalities that are considered core by us, and through which a simple event listing site can be created.  
Moreover, there are many other features present in the advanced functionality through the option of add-ons. These add-ons are very useful for the core plugin and 
also they help you to fund the support and development of the core.  This is not all as there are more Pro Add-ons plugin features that may interest you, they may include;
•	The calendar for displaying the event date for all the upcoming events. 
•	The google maps that may let you know the exact location of your event.
•	The registration allows the people to register for your events. 
•	The WooCommerce Sell Tickets are able to sell tickets live and so you can even keep a track of it. 
These might just be some as Pro Add-ons plugin features also include event alerts, bookmarks, embeddable event widget, sliders, organizers, contact organizer, email, event tags, google analytics, and logs. I know all these options might make you drool as it is a complete package.  
Conclusion
On the whole, the WP plugin is easy to install and is very easy to operate and so it will not at all give you a hard time while you are working on your website. Also if you are an event management agency or want to come up with an event management website then WordPress plugin is a full and final option for you that is at no cost going to let you down in what you want to do. It not just gives you options but gives you its best and complete features on the go while you save your time and so you don't have to look for any other options here and there apart from the WP plugin. With your chances, you can easily adapt to this website as it is very easy and will keep on helping you on its own and help you build up a website that is the most suitable for you.

                            </p>
                        </div>
                    </div>
                </Modal>
                <Footer/>  

            </>
        )
    }
}
