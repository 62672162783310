import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer'
import Garuntee from '../component/Garuntee'
import Header from '../component/Header'
import Preloader from '../component/Preloader'
import PriceCalculator from '../component/PriceCalculator'
import Steps from '../component/Steps'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'
import pressrelease1 from '../images/pressrelease1.png'
import pressrelease2 from '../images/pressrelease2.png'
import pressrelease3 from '../images/pressrelease3.png'
import pressrelease4 from '../images/pressrelease4.png'
export default class Press  extends Component {
    constructor() {
        super();
        this.state = {
            setIsOpen: false,
            setIsOpen2:false,
            setIsOpen3:false,
            setIsOpen4:false
        }
    }
    openModal() {
        this.setState({ setIsOpen: true })

    }
    openModal02() {
        this.setState({setIsOpen2:true})
    }
    openModal03() {
        this.setState({setIsOpen3:true})
    }
    openModal04() {
        this.setState({setIsOpen4:true})
    }
    closeModal() {
        this.setState({ setIsOpen: false })
        this.setState({ setIsOpen2: false })
        this.setState({ setIsOpen3: false })
        this.setState({ setIsOpen4: false })
    }
    render() {
    return (
        <>
        <Helmet>
                <title>Buziness Writing | Press Release</title>
            </Helmet>
            {/* <Preloader/> */}
               <Header/>  
                {/*header section start*/}
               <section className="hero-section ptb-100 gradient-overlay" style={{ background: 'url("img/header-bg-5.jpg")no-repeat center center / cover' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10">
                                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                    <h1 className="text-white mb-0">OFFER A POWERFUL EXPRESSION WITH A CREATIVE PRESS RELEASE</h1>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn check-btn mt-3">Get Started</a>
                                    <h3 className="text-white mt-3">
                                    We pick the correct words to more readily portray your business contributions for better correspondence and effect.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*header section end*/}

                {/*about section with promo start*/}
                <section className="about-with-promo ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <h2>PRESS RELEASE</h2>
                                    <span className="animate-border mb-4" />
                                    <p>
                                    An official statement is a corporate news thing to report new items or administrations to the business or crowds. All the more regularly an official statement is composed and distributed on PR sites that are being trailed by various business profiles or proprietors.
                                    </p>
                                    <p>
                                    An elegantly composed Press Release helps business people and organizations to contact the greatest number of individuals related with their specialty of business through an elegantly composed and exact official statement.
                                    </p>
                                    <ul className="check-list-info">
                                        <li><strong>focused content</strong> Increase brand affinity – brand</li>
                                        <li><strong>Engage your audience </strong> – with the right content</li>
                                        <li><strong> Beat the competition </strong> – rank high above</li>
                                        <li><strong>Keyword centric</strong> content – for better SEO ranking</li>
                                        <li><strong>appealing content</strong>Retain existing clients with share of mind –</li>
                                        <li><strong>targeted content</strong> Convert leads to customers –</li>
                                     
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-promo-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/increase.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p >Document - 01</p>
                                                {/* <button >Click Me</button> */}
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal02()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/search-engine.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 02</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal03()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/credit-card.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 03</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal04()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/interface.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 04</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*about section with promo end*/}
               <PriceCalculator/>
                <Garuntee/>
                <Steps/>
                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <img src={pressrelease1} style={{width:"100%"}} className="img-responsive"/>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen2}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                          <img src={pressrelease2} style={{width:"100%"}} className="img-responsive"/>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpen3}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                           <img src={pressrelease3} style={{width:"100%"}} className="img-responsive"/>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen4}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                         <img src={pressrelease4} style={{width:"100%"}} className="img-responsive"/>
                        </div>
                    </div>
                </Modal>
                <Footer/>  
        </>
    )
}
}
