import React, { Component } from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import Preloader from '../component/Preloader'
import PriceCalculator from '../component/PriceCalculator'
import Garuntee from '../component/Garuntee'
import Steps from '../component/Steps'
import { Helmet } from 'react-helmet'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'

export default class BlogPost extends Component {
    constructor() {
        super();
        this.state = {
            setIsOpen: false,
            setIsOpen2:false,
            setIsOpen3:false,
            setIsOpen4:false
        }
    }
    openModal() {
        this.setState({ setIsOpen: true })

    }
    openModal02() {
        this.setState({setIsOpen2:true})
    }
    openModal03() {
        this.setState({setIsOpen3:true})
    }
    openModal04() {
        this.setState({setIsOpen4:true})
    }
    closeModal() {
        this.setState({ setIsOpen: false })
        this.setState({ setIsOpen2: false })
        this.setState({ setIsOpen3: false })
        this.setState({ setIsOpen4: false })
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Buziness Writing | Blog Post</title>
            </Helmet>
                {/* <Preloader /> */}
                <Header />
                {/*header section start*/}
                <section className="hero-section ptb-100 gradient-overlay" style={{ background: 'url("img/header-bg-5.jpg")no-repeat center center / cover' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-10">
                                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                    <h1 className="text-white mb-0">Skirt THE JARGONS, COMMUNICATE WITH CLARITY</h1>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn check-btn mt-3">Get Started</a>
                                    <h3 className="text-white mt-3">Set life  into your websites with our smart and exact blog administrations that taps the correct crowd interest</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*header section end*/}

                {/*about section with promo start*/}
                <section className="about-with-promo ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <h2>BLOGS</h2>
                                    <span className="animate-border mb-4" />
                                    <p>
                                        We, in corporate, offer blog writing management. Our blog content authors are well-suited for providing content that applies to your details as well as being up-to-date and up-to-date. This way every blog entry that is created fits you perfectly and is 100% unique.
                                    </p>
                                    <p>
                                    Given our aptitude recorded as a hard copy connecting with content and the colossal abilities of our blog composing group, we ensure blog entry creation that will have your intended interest group stuck to your websites.
                                    </p>
                                    <ul className="check-list-info">
                                        <li><strong> SEO based, </strong> keyword centric content</li>
                                        <li><strong> Creative content - </strong> 100% original Content.</li>
                                        <li><strong> Quickest turnaround - </strong> time – 2 working days.</li>
                                        <li><strong> Reviewed by editors - </strong> error free copy.</li>
                                        <li><strong> Non plagiarized - </strong> Unique content Guranteed.</li>
                                        <li><strong> Rights of ownership - </strong> maintained – No reselling.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-promo-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/increase.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p >Document - 01</p>
                                                {/* <button >Click Me</button> */}
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal02()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/search-engine.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 02</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal03()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/credit-card.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 03</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal04()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/interface.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 04</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*about section with promo end*/}
                <PriceCalculator/>
                <Garuntee/>
                <Steps/>
                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>The Twist of Farfetch and Winter </h2>
                            <p>
                            Finally, bells of winters can be heard loud and clear; while winter is tightening its steps around the region, it is time to update wardrobes. Pull out all of the summer clothing and replace it with the winter stuff, but reusing the old winter stuff won't really be satisfying as winter is all about new, cozy stuff which smells pleasant, clean and brand new. It is not just about brand new clothes, but more importantly, it is about the trend that has to be followed, it is necessary for people to be up to date about the current fashion trends that can make you dazzle all around the winter season.
Brand new and trendy stuff might be a cliché yet another most important thing is the brand you shop from, and that brand does make a huge impact on your style, design, quality, and trend. People at times choose to shop from local brands because they believe that the brands are too expensive to even to look up to, but the sad part is that they see their so-called cheap local branded stuff get desiccated leaving a bad impact around the people you are partying with at a deluxe location. 

                            </p>
                            <b>Catch Your Trend with Farfetch </b><br/>
                            <p>
                            Well, well, well! You don't have to run anymore catching up to trends, price, and quality because The Coupons Promo has more than that to offer you just through a single brand, this brand is very eminent due to all the products it offers, as those products are exotically trendy, have striking prices and outstanding quality which are going to last more than you think. Oh yes! You have been picking up the right guesses, that brand is none other than Farfetch. Farfetch store is here to enhance your winter look and make you stand out amongst the crowd with their winter clothing, accessories, and shoes. Farfetch gives you the attitude to look emblemizing in what you wear knowing that the picks from this brand are never old or any less bold.
There is a long list of products Farfetch has to offer in the category of men, women, and kids so that no one from the family has any less as a whole look. Talking about the latest collection of the exclusive Acne Studios x Fjällräven where the two Swedish powerhouses come together for a brand new collaboration. Think padded outerwear, technical separates and Fjällräven’s instantly recognizable backpack to go with all of you winter outfits– truly turn into some glamorous, punchy, 	poppy tinge, which is going to look mind-blowing on whosoever wears it.
            <br></br><b>The Mega Farfetch Black Friday Sale </b><br></br>
            Apart from all the fashion trends Farfetch sets, it also offers the most luxurious Black Friday Sale, and as Black Friday is almost around the corner so Farfetch brings an end to rushing to shopping malls and standing in the queue for hours just to get the perfect outfits, matching shoes and the incomparable accessories. All you have to do is to enter the website of Farfetch and get any dress, handbag, stilettos, etc. through the classy Black Friday Sale 2019. All you have to do it to just click on the product you want and get it in your cart. Order your items on your doorstep without any hassle or getting into a fight while getting the things you wanted. 
Even while having a grand Black Friday Sale, Farfetch at no cost lowers down its quality of products and services offered. Get your hands on the most striking items that Farfetch has to offer on this Black Friday Sale 2019. Like every year, Farfetch has set its fashion trends, vibes and a collection that is extremely different like any other year. Therefore you can get your hands on the unique products that have been offered on such a grand scale. 
Black Friday sales offer everything from clothes to shoes to accessories, and you can easily find the products that you have been longing for all year round. This is the time of the year where you can be fabulous even while picking up items from the big bang sale that has more than just discounts to offer at an amazing rate. Farfetch also makes sure that the items they put on sale are exactly the same as their items that haven't been put on sale, ensuring the
same glam and authenticity. Look out for the Friday tote bags by Etudes, Friday Club T-shirts by Chinti and Parker, black gold diamond drop stud earrings by Colette, black gold ruby and onyx beaded bracelet by Shamballa Jewels, Halan black by Sandro Paris, and the list goes on and on but the impressive items don’t end. 
                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen2}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>IMPACTS OF POST MIGRATION AND REFUGEE MENTAL HEALTH </h2>
                            <p>
                            As the number of asylum seekers and refugees in increasing globally, day by day, mental health professionals have increasingly recognized the need to respond to mental health and understood its importance. Mental disorders cannot be predicted by pre-migration trauma but the post-migration trauma is most likely to influence mental health in a powerful manner. Furthermore, factors of post-migration may restrain the refugees’ ability to recover from the trauma of pre-migration. The significance of the relationship between stress and post-migration calls for control conditions and other related interventions that are effective for the mental health of the ones migrating from one place to another. This paper will discuss the impact of post-migration conditions on mental illnesses among asylum seekers and refugees particularly in Australia
                            <br></br>
                            <b>Determinants of Refugee Mental Health in Context of Post-Migration </b>
                            <br></br>
                            Presently, an unprecedented number of people are undergoing forcible displacement. In 2016, 65.6 million people across the globe were displaced against their will which is the largest number ever recorded. Among these, nearly 22.5 million were calculated to be refugees who migrated across international boundaries (Silove et al., 2017). According to the 1951 Convention Related to the Status of Refugees, refugees can be defined as people who have fear of being persecuted on the basis of their religion, political beliefs, race, membership in a sexual group or society, nationality, and political beliefs (Sim, 2016). These refugees cannot depend on their country or national identity for protection. The latest migration flows into Europe and Australia have increased on a vast scale. Consequently, the needs of refugees and those seeking asylum are excessively addressed due to which the demands for appropriate mental health services have also augmented.      
	Approximately 15% of foreigners residing in Australia undergo certain mental health issues. The possible reasons may include having weak access to care and suffering from discrimination in the foreign land and other challenges related to migration (Guajardo et al., 2016). Regular migration is a prime factor of the growing population in Australia so mental health outcomes among different communities may have tremendously long-term effects in context of health, as well as socially and economically.    
	Australia has largely emphasized on its immigration policy by focusing on skill as a part of its selection criteria. Entrants are required to satisfy a “points test” that is a selection of migrants according to their age, language, education, and occupation (Fazel and Betancourt, 2018). A fraction of this test also includes certain health requirements that need to be fulfilled. Greater mental health is to be influenced while considering factors such as age, marital status, educational attainment, household earning, and gender (Guajardo et al., 2016). Unemployment and failing to be a part of a labour force may also lead to remoteness and exposure to mental illnesses. 
The signatories of the Convention provide protection and unique to convention refugees. However, the way in which these rights are offered and implemented may vary and are most likely to change as a result of political will resulting in various conditions of asylum within and between 
countries. The process of seeking asylum is correlated with various stressors and weak mental health outcomes (Miller and Rasmussen, 2017). Preliminary acceptance must be awaited for prior to accessing just temporary permission for employment purposes. Therefore, they may spent several months or even years without having access to legal employment sources (Silove et al., 2017). As for some asylum seekers, permanency may turn out to be a remote or the most impossible outcome, hence, leaving them as vulnerable as ever. 
<br></br><b>Conclusion </b><br></br>
As the general population, refugees are mainly influenced by various factors of mental health. However, the likelihood of negative social conditions of post-migration increases according to the nature of experience of refugee migration, regional and national policies of limitation and migration, and attitudes of public towards refugees. Post-conflict conditions interrelated with the process of migration including experiences of confinement, extended status of insecurity and restraints on the ability to find employment options and/or housing is most likely to have a strong influence over mental health. 
Regardless of the exposure to multiple risk factors, various refugees who have decided to permanently resettle stay safe from mental disorders. However, they are known to exhibit flexibility in a remarkable manner. Moreover, such flexibility can be destabilized by their current conditions. The emphasis on post-migration stress and related mental disorders signifies depression, anxiety, and problems of loss in relationships, identity and family. It can be concluded that the relationship between the impacts of post migration and refugee mental health holds great importance and that it can be improved by means of interventions and other health services in Australia and various other parts of the world. 

                            </p>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpen3}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Happy Shopping with Kohl’s  </h2>
                            <p>
                            The clock is ticking to the end of another month and then entering the end of the year. We can look back to all the happy moments we have spent, and pick out the best ones, well most people are going to pick out the shopping they have done, the satisfying shopping was the greatest achievements amongst all the happy moments as who wouldn't want to avail the amazing discounts and promo codes from the most trending and your favorite brands. They were a true bliss to wear and can't be forgotten at all, well happiness is happiness and shopping can be the best kind of satisfaction.
Well, the year is ending that doesn’t mean that discounts on your desired brands have ended, because as the time ends, the fun increases to a greater limit and that is what we are planning to bring in front of you. All you need to do is buckle up as we have another announcement to make, and that is from one of your loved brands “Kohl’s”. Oh yes, the well-known brand, on which everybody would like to keep their hands on, the silk scarfs, the gorgeous tops, the smart coats, and whatnot, all you can get in your money frame.
Kohl’s offers an extra 25% off with your family and your friends’ coupons with the promo code "YOUGET25". Enjoy your discounts and promo codes all over your choice of products, and with no restrictions. Why won't you want to get their hands on all the items you have ever wished for? All are available in men, women and kids' clothes and other accessories. Kohl’s is not just going to offer you discounts but also quality and quantity, and Kohl’s has the top priority of not compromising on their top lists. 
Men's' Sale Incoming! Dockers through Kohl's has it is amazing yet jaw-dropping Holiday Sale upcoming with the great variety of Ultimate Chinos, not just that but the entire stock of pants, which has a remarkable variety which is not going to let you stop buying more and more pants for your winter parties. You can rock and roll in your new, comfortable and branded pants which are going to last more than you ever thought. Well, Kohl's is here to fulfill your promised orders with the best quality and in the limited money frame. Get ready to get smart again with your favorite pants you get from Dockers through Kohl's.
Well, winters are there and who wouldn’t want to look smart and have comfortable footwear that keeps you warm as well as it gives you the vibe of style. You should also know that Kohl's not just offers 25% off on the entire stock or the branded pants but you can easily avail the grand Boot Sale which offers some comfortable boots at $39.99 and under, isn't that an outstanding deal? Oh yes! You can avail so much in just your affordability range. Also, you can get an extra 25% off with the promo code YOUGET25, and get boots for all family members and enjoy your deal, dancing, jogging or parting wearing the luxurious boots.  
Then don’t stop scrolling down the page of your favorite brand, Kohl’s. Pick up the exotic items add them in your cart and click the order button, and see your shopping bill magically minimizing through all the discounts and promo code. That is what we call cherishing moments, Kohl’s and happiness.
•	25% off purchase with code YOUGET25
•	Men’s Sale on entire stock of pants in Dockers
•	Boots at $39.99 and under on boots of men, women and kids
•	25% off and an extra 25% off with promo code YOUGET25
•	25% off on Adidas


                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen4}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>The Future of Small Business IT </h2>
                            <p>
                            From an appliance to any software, technology is a small business owner’s best friend. IT has revolutionised thus helping people work more efficiently, through such means one can make more customers yet saving most of the money. Moreover, we can even notice every business going digital, it is going online and running its customer relationships, integrating trading systems and management. Upgrading organizations through IT has given entrepreneurs a broader vision and better understanding of improving their businesses. While improving small business, entrepreneurs will need to move their marketing techniques to provide prospects and customers with the right information, at the right time, in the right context. 
Talking about business IT, It has been noticed that personal and small business sectors have been major contributors to the economy. Furthermore, linking small businesses with IT has shown substantial growth in the digital infrastructure, has cut down costs of starting and running small businesses, this advantage has lowered the competitive barriers and has led to innovative creations of business models.  
The upcoming decade can easily perceive the reality of IT and can catch up with the hype of the previous decade. Wireless and broadband networks will become omnipresent, cheaper, yet increasingly advanced. The computing power used by such entrepreneurs will greatly advance the analysis, collection, and sharing of data. The web will become an advanced platform that dispenses small businesses with a wide range of tools, capabilities, and services. Advancing technology will become easier, cheaper to use and deploy, and pervasive.  
Getting IT services is an immense help for newbie entrepreneurs who are setting up SME Business. Many of the IT service contributors that work with SME businesses are known as managed service providers. A variety of specifications can be encompassed, but talking about the basics, it allows the service of the company's network to process according to an ongoing basis and on a regular rate. Yet, mastering technology has been a great challenge for such organizations, but entrepreneurs are embracing technology solutions to make their lives and their employees live easier. Advancement in IT will in no time result in a linked world where the networked mobile services, digital intelligence, and analytic tools augment and support small business management and operations. The adaptive digital infrastructure will allow significant flexibility around where, when and how the work is done. Moreover, it allows small business managers to direct their business on their own terms and their own time and help their organization provide work-life balance. Furthermore, IT support departments in the organizations make sure that the companies are up to date and running according to the trend, even while being flexible in work they do not miss out updates. 
Leveraging the evolving digital infrastructure, easy to use and inexpensive tools and web services will increase the capability of small businesses to build multifaceted online applications, and communicate, create and share information.  The emergence of the IT platforms in both virtual landscapes and real-life physical world- will drive small business development, operation, and innovation.

                            </p>
                        </div>
                    </div>
                </Modal>
                <Footer />
            </>
        )
    }
}
