import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer'
import Garuntee from '../component/Garuntee'
import Header from '../component/Header'
import Preloader from '../component/Preloader'
import PriceCalculator from '../component/PriceCalculator'
import Steps from '../component/Steps'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'

export default class Brochure extends Component {
    constructor() {
        super();
        this.state = {
            setIsOpen: false,
            setIsOpen2:false,
            setIsOpen3:false,
            setIsOpen4:false
        }
    }
    openModal() {
        this.setState({ setIsOpen: true })

    }
    openModal02() {
        this.setState({setIsOpen2:true})
    }
    openModal03() {
        this.setState({setIsOpen3:true})
    }
    openModal04() {
        this.setState({setIsOpen4:true})
    }
    closeModal() {
        this.setState({ setIsOpen: false })
        this.setState({ setIsOpen2: false })
        this.setState({ setIsOpen3: false })
        this.setState({ setIsOpen4: false })
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Buziness Writing | Brochure Writing</title>
            </Helmet>
                {/* <Preloader/> */}
               <Header/>  
                {/*header section start*/}
               <section className="hero-section ptb-100 gradient-overlay" style={{ background: 'url("img/header-bg-5.jpg")no-repeat center center / cover' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10">
                                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                    <h1 className="text-white mb-0">ENGAGING CONTENT THAT DERIVES SUCCESS AND REVENUE</h1>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn check-btn mt-3">Get Started</a>
                                    <h3 className="text-white mt-3">
                                    We compose leaflet content that is an ideal equilibrium of deals tone also, item data, that gets the correct outcomes.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*header section end*/}

                {/*about section with promo start*/}
                <section className="about-with-promo ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <h2>BROCHURE WRITING</h2>
                                    <span className="animate-border mb-4" />
                                    <p>
                                    A leaflet is your data outlet under the control of the client, an inadequately composed handout will lead the client to lose interest and ultimately moving away from you. Then again a leaflet that conveys quality data as well as has a tone that gets some information about their necessities.
                                    </p>
                                    <p>
                                    Our leaflet content composing group has long periods of applicable promoting and publicizing experience, subsequently they can think of substance that applies straightforwardly to pamphlet writing in an imaginative way.
                                    </p>
                                    <ul className="check-list-info">
                                        <li><strong>Content Writing</strong> Sales pitch tone of</li>
                                        <li><strong>Creative content</strong> – – written exclusively for you</li>
                                        <li><strong>Product/Service centric wordings </strong> to make the right connection</li>
                                        <li><strong>2 working days</strong> Swiftest turnaround time –</li>
                                        <li><strong>checked </strong>All copywriting – by editors</li>
                                        <li><strong>no reuse policy</strong> All content is your property</li>
                                     
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-promo-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/increase.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p >Document - 01</p>
                                                {/* <button >Click Me</button> */}
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal02()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/search-engine.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 02</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal03()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/credit-card.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 03</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal04()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/interface.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 04</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*about section with promo end*/}
               <PriceCalculator/>
                <Garuntee/>
                <Steps/>
                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>E-Cigarettes</h2>
                            <p>
                            In recent times the use of e-cigarettes has grown promptly specially amongst the female crowd of universities. E-cigarettes started as a crowd entertainer but it has outgrown entertainment into addiction due to which the women have adapted to it. According to the study conducted (Almutham et al., 2019), just about one-tenth students said that they use e-cigarettes. Some students believed that e-cigarettes are for people who are trying to put a stop to their smoking habit. On the divergence, students believed that e-cigarettes are just an addiction and that addiction is leading to health issues and those aren’t just mental issues but are also internal body issues. The study concluded with the aspects saying that most of the students had no knowledge regarding e-cigarettes and thus there was a need to aware students about them, and students should know the fact that e-cigarettes might be a replacement for tobacco but itself it is really harmful. 
The use of e-cigarettes has started to prevail in the US and also in the Middle East most importantly in UAE; ever since they were introduced in the market which is almost a decade ago. A study was conducted (Obisesan et al., 2019) which thoroughly examined the relationship between depression and the use of electronic cigarettes especially in the adult females of the US; the main outcomes of the survey were the clinically diagnosed people who held depression. It was found that there was an association between depression and e-cigarettes which majorly highlighted the longitudinal peril of depression in people by using electronic cigarettes; thus people with mental health issues and use of e-cigarettes were there in much a greater population. The study moreover provides evidence to bridge an association with depression and e-cigarettes and signify implications for clinical practices, public health, and health policy. Such an issue was not just faced in US but also a great percentage of adult women have also been affected in such a manner in UAE. The results also acquired that e-cigarette use was linked with a major increase in mental health or depression symptoms over a year.  

                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen2}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Dissertation Services </h2>
                            <p>
                            All of the college students know about the fact that the dissertation is one of the most important tasks. If done properly, students can get their hands on the grade they have ever wished for. Dissertation papers can depict your whole grade and is the most difficult task which students have to pass. Most of the teachers give majority marks on the basis of a dissertation paper.  One cannot just achieve grades in this case as the dissertation requires research and ways that are applicable for its format
Many students find it very hard to choose topics and then start up with their dissertation papers. They get so confused and terrified by the thought of their dissertation. So to stay away from such tension, students often hire services. These are mainly the dissertation writing services that tend to write the dissertation for the student and submit it before time.
It is not that all dissertation services that you find online are authentic and give you work of your choice. Many of these websites do not have properly hired writers and one writer seems to do all kinds of writing. Thus we are here for you with our dissertation services that you are going to be satisfied from.
Why use a dissertation service 
Not everyone can write a dissertation essay that is top-notch and expects to get amazing grades. Dissertation essays do require time and a concept that cannot be gained by everyone. Also, students who are given to write dissertations are not really experienced, thus they do not know that what they are writing in the dissertation makes sense or not. So it is fairly important for students to seek the help of the people who are experienced writers and know how to write a good dissertation. 
By using a dissertation service one can get rid of the burden as the services as going to provide you with dissertations that are written by experts. You might be a newbie at writing a dissertation. While the one you hire is going to be a pro at it. The writer is going to be the one who is highly qualified and has written a number of dissertations. You know what to do, just a hire the dissertation service and relax till you get it back. 
Also, as the dissertation is written by experts, and they have a lot of knowledge about dissertation papers it will be without a single mistake. All the language, tone, grammar, and topic would be without any single mistake.
Dissertation Writer
It is extremely difficult to write a dissertation on your own, the help of any dissertation writing is really necessary. While you hire a service for writing, all of it depends on the type of writing you want to get done. It can be an essay to write, or a thesis, or an assignment, or even a dissertation. With every type of writing comes a different writer. We have hired writers on the basis of the work type they do best. We feel that not every writer is able to do any type of writing. If focused on one type, a writer can master its skills in it.
If you come to our page to hire a dissertation writer all you have to do is fill up the important pointers for the writer to know. You then will not have to spend the whole time worrying about getting your dissertation done, instead, you will have it in your hand ready to be submitted according to your time.
Our dissertation writers are masters in writing a dissertation. They are highly qualified and are extremely polished when it comes to writing dissertations. Your work will be handed to the writers who are highly qualified in the field of the subject you want your dissertation on.

                            </p>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpen3}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Coca Cola </h2>
                            <p>
                            Marketing strategy can be referred to as a plan of action that is designed for the promotion of a product in various possible ways. Such strategies provide a platform for the company to expand its business extravagantly. Coca Cola as the world’s highly ranked soft drink company has adopted several wise marketing strategies. Currently, Coca Cola is operating in more than 200 countries across the globe and is known for generating nearly 60% of its revenue. Furthermore, 80% of its operating profit is produced from outside the U.S. As a result of its commendable marketing strategies, Coca Cola has a powerful brand recognition around the world. In this context, this report will evaluate the marketing strategies for Coca Cola and the way they can be improved. 
                            The reason why Coca Cola is grouped among the most leading soft drink beverage industries is that its marketing strategy has always aimed for associating happiness, good life and positivity with its buyers. Consequently, it majorly contributes to the maximum sale of soft drinks worldwide. Today, Coca Cola uses the most complete and varied strategies which is why it is mostly known as a ground-breaking organization. The marketing mix including the 7P’s plays a significant role in the creation of a successful marketing strategy. Moreover, it is also essential for the successful implementation of their marketing concept (Smarandescu and Shimp, 2015). The 7P’s to be covered in this section include – product, price, place, promotion, packaging, positioning and people. 
	The first of the 7P’s – product, is studied by developing an understanding for the vast range of products. Globally, few of the products offered by Coca Cola include – Fanta, Sprite, Coca Cola, Coca Cola Zero, Diet Coke, and many more. All of these products by Coca Cola are sold in multiple sizes and packaging. Its core product Coke, for instance, is sold in bottles of plastic, glass and cans (Schmidt et al., 2016). The quantity of the drinks in these containers can have a range of 200ml, 500ml, 1ltr, 1.5ltr, and 2ltr. On each of these bottles and cans, Coca Cola’s logo is made clearly visible in order to keep it differentiated from Coke. These bottles also come in different and unique shapes that pertain to the brand effectively. 
	The second P – price, is followed by Coca Cola as a strategy of discrimination in its marketing mix by charging different prices for different products. The beverage market is deliberated to have many buyers 
    but few sellers. Presently, the two dominant players in the market are Coca Cola and Pepsi (Kee and Yazdanifard, 2015). The prices of Coke products are similar to that of Pepsi products in that specific section. In case one of these two companies raises its prices, buyers across the globe will switch to the cheaper one specifically in areas where buyers are sensitive to price (Gillespie and Riddle, 2015). However, upon generous purchases, discounts are offered by Coca Cola at times by even bundling its products.  
	The marketing mix in terms of the 3rd P – place, highlights Coca Cola’s position while it operates in 200 countries of the world. Subsequently, it has developed a vast network of distribution. Using its secret formula, the beverage is produced by Coca Cola and transported to the bottlers positioned in various parts of the world (De Mooij, 2018). The company predefines the shapes and sizes of the bottles which are filled by the bottlers with beverage in adequate amounts, ready to be shipped to the forwarding and carrying agents. Mostly, goods are transported from the distributor to the wholesalers who are then assigned to distribute them to the retails in accordance with the demand (Cohen et al., 2018). Products of Coca Cola are also known to be distributed to a multiple number of Hotels and restaurants, globally.       
	The 4th P of the Coca Cola marketing mix – promotion, sets a bench mark for its branding and advertising. Its promotional strategy pays significant attention to aggressive marketing via ad campaigns including the use of media such as online ads, sponsorships, various print media, TV, etc. Coca Cola participates in major sponsorship events such as Olympic Games, FIFA, America, Idol, etc. It also launches its TV advertisements in many national languages (Bragg et al., 2018). At supermarkets, independent shelf spaces for Coca Cola are significantly emphasized so as to highlight the products even more.  
    The 5th P of the Coca Cola marketing mix – packaging, is one of the most important physical factor of the product to attract its buyers to a great extent. The outlook and appearance of the product leaves a great impression on its customers which is why every visual element must be evaluated on a regular basis so as to ensure that its buyers are automatically attracted to it (Bragg et al., 2016). The packaging of a product motivates potential buyers to purchase the product. Moreover, the packaging is also essential for conveying informative messages about the product such as its transport, how to use, recycle or dispose.
	Another important P of the marketing mix – positioning, is a significant factor. Positioning of a product assists the customers in determining how the products’ attributes can be communicated in the best possible manner, in relation to the needs of customers, competitive pressures, etc. If a product is successfully positioned, resonation of marketing messages with target consumers is ensured (Schmidt et al., 2016). In order to ensure efficient product positioning, the most appropriate communication channels are chosen and the key messages are to resonate with the buyers. 
	The last P of the marketing mix – people, highlights the importance of customers who are crucial for every business or product. This is because people are the ones who relate to the product and are linked with it, entirely. If there are no people, there will be no consumers, and the business will turn out to be a pointless investment. There are various kinds of people all over the world who play their role differently and contribute to the running of every industry (Kee and Yazdanifard, 2015). Therefore, they are also to be studied well and given great importance so as to improve the product in this context. People are liable to work with the product’s promotion, sales, marketing, and various other business activities. 
    Considering all the 7P’s of the marketing mix for Coca Cola, main focus by the company is laid on its strength while eliminating the weaknesses of the products being marketed for improvisation. Consequently, customer demands and expectations are fully met. The pricing factor is discussed so that it can be matched well with the target groups. Place of product marketing is then focused by Coca Cola as it addresses to the distribution channels for delivering and selling products to the customers (Cohen et al., 2018). Moreover, promotion of the product motivates customers towards buying the product hence improving its sale globally. People, positioning and packaging are few of the other factors in the marketing mix formula that ensure the prosperity of the company. 

Marketing Strategy and Marketing Mix of Coca Cola 
	Coca Cola is an invention of John Stith Pemberton in 1886 which was meant to be a drink of introducing good health and stamina. This drink was first sold by him for five cents per glass at the establishment at the Jacob’s Pharmacy in Atlanta Georgia. Progressively, Coca Cola got introduced in several other countries and gained recognition as a result of its incomparable taste and quality (Kee and Yazdanifard, 2015). The soft drink is presently available officially in almost every country except for North Korea and Cuba. However, in both countries it is known to be available as a grey import.  

                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen4}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Smartphones Of 2020  </h2>
                            <p>
                            In the era of technology, the cellphones have been standing out in all, bringing the most in such a small yet handy device. Nowadays phones have not just been used for entertainment but all the important chores can be done through cellphones like checking a Fake ID, checking your health rate, coping up with games and entertainment, and whatnot. Cellphones have a world on their own where the connectivity is high and thus you can know everything that is going around the world. At first, cellphones were just an invention but now it is simply very difficult to live without one. We’ve listed doe the best of the cellphones so, here are the top 10 most anticipated smartphones of the year 2020;
1-	iPhone 12 pro 
The world is after the most famous iPhone as it has taken over the whole world with the best. The iPhone 11 and 11 pro had no doubted the best cameras back in the year 2019, well not just the camera but it upgraded its battery life to a great extent, yet anyone who buys it has to pay for it heavily. 
This year everyone waits for a bigger hit by iPhone which would surely be iPhone 12 and so it is meant to play successfully in the market with bigger yet better features that are going to leave everyone astounded.
2-	Samsung Galaxy G20 
In the year 2019 Samsung did hit hard with the Samsung Galaxy S10 and its variants. This year Samsung should have brought up the new Galaxy series but this is not the case this time. In the year 2020 Samsung decides to do something new with the new decade and thus they are going to bring forward the brand new series of Galaxy that is going to be named Galaxy S20.
New series come up with new variants that are going to be the most exclusive with the stunning wide camera with 108MP (26mm), also 48MP (102mm), another 12MP (13mm) and lastly the 0.3MP (Time of Flight) that will allow the state of art with 100x zoom. 
3-	Motorola Razr
On the most popular demand, Motorola Razr has tended to set another of its phones to the market. The Razr has another intervention that means that it is not going to be the old bulky flip phone but it is all going to be about the sleek style and look which is going to be an easy foldable phone.  
It is going to be about the folding screen and the advanced technology options. It is surely said that one won’t get to know that the screen is foldable once you know it. Motorola Razr sets the new trend to the style of the category of cellphones. 
4-	Google Pixel 4a
Google Pixel is going to come out with more of the wow factor and is going to be fully packed with advancements. The Google Pixel 4 and the 4XL is the level of excitement for all the Google Pixel lovers and so Google Pixel 4a is going to be more than that. Google Pixel 4a will comprise of the squared camera module at its back, and also the sensor for the fingerprint is going to exist at the back. Google will make some compromises to make the phone budget-friendly to all its customers. 
5-	Xiaomi Mi Note 10
With trends changing in every other phone, Mi has something more than usual to offer and thus is giving the best it has up till now. It is highly expected that Mi is going to release the phone that is going to be the most expensive phone of theirs till now. Not just something average but it is going to have the Penta camera setup with the ultimate camera of 108MPs, also an ultra-wide-angle 20MPs camera, a telephone sensor of 12Mps, the telephoto 5 MPs sensor and lastly the macro camera of 2MPs. Just like everyone, we also consider it to be the best phone in terms of camera, all we have to do is wait for the release.
6-	Huawei P40 Pro
We are finally here with the official news about the new release of Huawei, the P40, the P40 Pro and the P40 Pro+. The Huawei P40 has a fortune to offer as it has a sleek 6.1 inches OLED screen, also including the Kirin 990 5G chip onboard. This is not all, this beautiful smartphone has a triple camera setup at its back and an extraordinary battery of 3,800 mAh. 
At the same time, P40 and P40 Pro+ both have curved 6.8 inches OLED screens and have an extremely high resolution. It also includes the ultra-technology of wireless charging and reverse wireless charging. The Pro also comes with a quad-camera setup with 10x zoom which is hybrid, in the meantime, Pro+ has another camera lens that gives up 20x hybrid zoom. You should also know that with Pro+ you can get more storage.
7-	OnePlus 8
The year 2020 has more to offer and thus we get to know that OnePlus 8 is being released this year with the perfect improvements that should have been done in the OnePlus 7t. OnePlus 8 offers its users a 6.55 inches wide screen that has a resolution of 1080p and an AMOLED screen. This is not all, it comprises of Snapdragon 865 chip with triple camera setup and a power-packed battery of 4300mAh. With this, the OnePlus 8 Pro will also be set out in the market with a similar design to the OnePlus 7T but with higher quality and advanced technology which is surely going to leave everybody stunned.
8-	Microsoft Surface Duo
Microsoft tends to so something that is going to take its users off the hook, yes it is no other than the Microsoft Surface Duo. This smartphone has two screens and it runs Android. Acquiring the style of a Gorilla Glass cover it is of 5.6 inches displays which have been joined by a hinge in the middle. It is not completely foldable but they can completely rotate 360 degrees and so this is not just a single purpose device but can be used in multiple orientations.
Like the other Android flagships, the Microsoft Surface Duo has the Qualcomm’s Snapdragon 855 processor. The whole look of the phone is not giving a complete idea of what it is going to be but we surely know that it has to go big so that people enjoy using it.
9-	Oppo Find X2 and X2 Pro
Oppo is going to enter the market with another bang of Oppo Find X2 and X2 Pro. Both of the phones have aa screen of 6.7 inches and those to QuadHD and a 120Hz which has the power to run the resolution and the refresh rate in simultaneous ways which have till now beaten the S20. Moreover, it has 65W wired charging which also has the latter seen in the Reno Ace. Both of the cellphones run on Snapdragon 865 processor and so they support 5G too. With that, they are also packed with 12GB of RAM. About the camera, the Pro has two of the 48MPs lenses together but X2 had a 48MPs sensor that is supposed to be the main one and also the 12MPs video lens that is dedicated with 5x zoom.
10-	Honor 30, 30 Pro and Pro+
Honor the sub-brand of Huawei is going to release Honor 30 which is going to set off the cellphone market on a complete basis. There is not just Honor 30 but also has 30 Pro and 30 Pro+. The phone is going to include an extremely powerful Kirin 990 processor and also an OLED display that is meant to be a waterfall, with a 50x periscope lens. This is not all, the sleek design of Honor 30, 30 Pro and Pro+ is going to beat most of the designs of multiple phones. 
These are the topmost releases of the cellphones that are going to bring a boom in the cellphone market and have already made the buyers super excited. It is because these are going to be the latest releases with the best technology till now, with advanced features that are going to make all these phones popular worldwide. Now everyone is waiting for the launches so that they can go and grab their favorites.

                            </p>
                        </div>
                    </div>
                </Modal>
                <Footer/>  
            </>
        )
    }
}
