import React, { Component } from 'react'

export default class Steps extends Component {
    render() {
        return (
            <>
             {/*work process section start*/}
             <section className="work-process-section ptb-100 gray-light-bg">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-lg-9">
                                    <div className="section-heading text-center">
                                        <strong className="color-secondary">Work Process</strong>
                                        <h2>HOW IT WORKS</h2>
                                        <span className="animate-border mr-auto ml-auto mb-4" />
                                        <p className="lead">We Follow a Simple and clear Process that Guarantees Transparency</p>
                                    </div>
                                </div>
                            </div>
                            {/* ============ step 1 =========== */}
                            <div className="row mt-5">
                                <div className="col-md-12 col-lg-5 process-width">
                                    <div className="process-box process-left">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="process-step-number">
                                                    <strong>Step</strong>
                                                    <h2 className="m-0">01</h2>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <h5>Sign-Up</h5>
                                                {/* to peruse our administrations. */}
                                                <p>Top off our short and brief structure to join. Login to your committed zone</p>
                                            </div>
                                        </div>
                                        <div className="process-line-l" />
                                    </div>
                                </div>
                                <div className="col-md-2 process-none" />
                                <div className="col-md-5 col-md-5 col-lg-5 process-none">
                                    <div className="process-point-right" />
                                </div>
                            </div>
                            {/* ============ step 2 =========== */}
                            <div className="row">
                                <div className="col-md-5 col-lg-5 process-none">
                                    <div className="process-point-left" />
                                </div>
                                <div className="col-md-2 process-none" />
                                <div className="col-md-12 col-lg-5 process-width">
                                    <div className="process-box process-right">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="process-step-number">
                                                    <strong>Step</strong>
                                                    <h2 className="m-0">02</h2>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <h5>Pick A Service</h5>
                                                <p>accommodates your necessities so we can get an unpleasant thought what you're top priority</p>
                                            </div>
                                        </div>
                                        <div className="process-line-r" />
                                    </div>
                                </div>
                            </div>
                            {/* ============ step 3 =========== */}
                            <div className="row">
                                <div className="col-md-12 col-lg-5 process-width">
                                    <div className="process-box process-left">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="process-step-number">
                                                    <strong>Step</strong>
                                                    <h2 className="m-0">03</h2>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <h5>Checkout</h5>
                                                <p>Review your order. We accept payments through credit cards, PayPal and pre-pay</p>
                                            </div>
                                        </div>
                                        <div className="process-line-l" />
                                    </div>
                                </div>
                                <div className="col-md-2 process-none" />
                                <div className="col-md-5 col-lg-5 process-none">
                                    <div className="process-point-right" />
                                </div>
                            </div>
                            {/* ============ step 4 =========== */}
                            <div className="row">
                                <div className="col-md-5 col-lg-5 process-none">
                                    <div className="process-point-left" />
                                </div>
                                <div className="col-md-2 process-none" />
                                <div className="col-md-12 col-lg-5 process-width">
                                    <div className="process-box process-right">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="process-step-number">
                                                    <strong>Step</strong>
                                                    <h2 className="m-0">04</h2>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <h5>Writer Deployed</h5>
                                                <p>A certified essayist relating to your predefined branch of knowledge is relegated</p>
                                            </div>
                                        </div>
                                        <div className="process-line-r" />
                                    </div>
                                </div>
                            </div>
                            {/* ============ step 3 =========== */}
                            <div className="row">
                                <div className="col-md-12 col-lg-5 process-width">
                                    <div className="process-box process-left">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="process-step-number">
                                                    <strong>Step</strong>
                                                    <h2 className="m-0">05</h2>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <h5>Product Delivered</h5>
                                                <p>The completed  order following your provided guidelines is uploaded to your account area</p>
                                            </div>
                                        </div>
                                        <div className="process-line-l" />
                                    </div>
                                </div>
                                <div className="col-md-2 custom-none" />
                                <div className="col-md-5 col-lg-5 custom-none">
                                    <div className="process-point-right process-last" />
                                </div>
                            </div>
                            {/* ============ */}
                        </div>
                    </section>
                    {/*work process section end*/}   
            </>
        )
    }
}
