import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
class Header extends Component {
  render() {
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
      <>
        <header className="header">
          {/*topbar start*/}
          <div id="header-top-bar" className="primary-bg py-2">
            <div className="container-fluid">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-7 col-lg-7 d-none d-md-block d-lg-block">
                  <div className="topbar-text text-white">
                    <ul className="list-inline">
                      <li className="list-inline-item"><span className="fas fa-headphones-alt mr-1" /> 24x7 Technical Support</li>
                      <li className="list-inline-item"><span className="fas fa-phone-alt mr-1" /> US Whatsapp No:  +1516 738 3453</li>
                      <li className="list-inline-item"><span className="fas fa-phone-alt mr-1" /> UK Whatsapp No:  +44 742 912 5487</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="topbar-text text-white">
                    <ul className="list-inline text-md-right text-lg-right text-left  mb-0">
                      <li className="list-inline-item">
                        <a href="#" className="rounded"><span className="fab fa-facebook-f" /></a>
                      </li>
                      <li className="list-inline-item"><a href="#" className="rounded"><span className="fab fa-instagram" /></a>
                      </li>
                      <li className="list-inline-item"><a href="#" className="rounded"><span className="fab fa-linkedin-in" /></a>
                      </li>
                      <li className="list-inline-item"><a href="#" className="rounded"><span className="fab fa-youtube" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*topbar end*/}
          {/*start navbar*/}
          <nav className="navbar navbar-expand-lg fixed-top white-bg">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img src="img/Buzinesswriting-01.png" alt="logo" className="img-fluid" height={250} width={250} />
              </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="ti-menu" />
              </button>
              <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto menu">
                  <li><a href="#" className="dropdown-toggle"> Blog & Articles</a>
                    <ul className="sub-menu">
                      <li><Link onclick={scrollTop} to="/blogpost">Blog Post</Link></li>
                      <li><Link onclick={scrollTop} to="/articles">Articles</Link></li>
                      <li><Link onclick={scrollTop} to="/newsletter">NewsLetter Content</Link></li>
                    </ul>
                  </li>
                  <li><a href="#" className="dropdown-toggle"> Web Content</a>
                    <ul className="sub-menu">
                      <li><Link to="webcopywriting">Webpage Copy</Link></li>
                      <li><Link to="/whitepapers">White Papers</Link></li>
                      <li><Link to="/productdescription">Product Descriptions</Link></li>
                    </ul>
                  </li>
                  <li><a href="#" className="dropdown-toggle"> Business Writing</a>
                    <ul className="sub-menu">
                      <li><Link to="/executivesumary">Executive Summaries</Link></li>
                      <li><Link to="/businessplan">Business Plans</Link></li>
                      <li><Link to="/reports">Report/Case Studies</Link></li>
                      <li><Link to="/presentation">Presentation PPT</Link></li>
                      <li><Link to="/proposal">Proposals</Link></li>
                      <li><Link to="/corporate">Corporate Profiles</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/press">Press Release</Link></li>
                  <li><a href="#" className="dropdown-toggle"> Seo Writing</a>
                    <ul className="sub-menu">
                      <li><Link onclick={scrollTop} to="/blogpost">Blog Post</Link></li>
                      <li><Link onclick={scrollTop} to="/articles">Articles</Link></li>
                      <li><Link onclick={scrollTop} to="/newsletter">NewsLetter Content</Link></li>
                      <li><Link to="/press">Press Release</Link></li>
                      <li><Link to="/productreviews">Product Reviews</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/resumewriting">Resume Writing</Link></li>
                  <li><a href="#" className="dropdown-toggle"> Others</a>
                    <ul className="sub-menu">
                      <li><Link to="/infographs">Info Graphs</Link></li>
                      <li><Link to="/ebook-writing">Ebook</Link></li>
                      <li><Link to="/proofreading">Editing/Proof Reading</Link></li>
                      <li><Link to="/brochure">Brochure Content Writing</Link></li>
                      <li><Link to="/videoscript">Video Script Writing</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/ordernow" className="btn secondary-solid-btn check-btn">Order Now</Link></li>
                </ul>
              </div>

            </div>
          </nav>
        </header>

      </>
    )
  }
}

export default Header