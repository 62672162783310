import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
// import faStyles from 'font-awesome/css/font-awesome.css'
const tawkTo = require("tawkto-react");
export default class Footer extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    tawkTo('5d2346a222d70e36c2a4b1b9')
  }
  render() {

    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }




    return (

      <>
        <footer className="footer-section">
          {/*footer top start*/}
          <div className="footer-top gradient-bg">
            <div className="container">
      <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <div className="row footer-top-wrap">
                    <div className="col-md-5 col-sm-6">
                      <div className="footer-nav-wrap text-white">
                        <img src="img/logo-white.png" alt="logo" className="mb-2 footer-img" />
                      <li className="list-inline-item"><span className="fas fa-phone-alt mr-1" /> U.K TOLL FREE: +44 800 041 8794</li>
                      <li className="list-inline-item"><span className="fas fa-phone-alt mr-1" /> U.K PHONE NO: +44 741 834 3231</li>
                      <li className="list-inline-item"><span className="fas fa-phone-alt mr-1" /> U.S PHONE NO: +1 646 916 5519</li>
                      <li className="list-inline-item"><span className="fas fa-phone-alt mr-1" /> US Whatsapp No: +1516 738 3453</li>
                      <li className="list-inline-item"><span className="fas fa-phone-alt mr-1" /> UK Whatsapp No: +44 742 912 5487</li>
                        <div className="social-nav mt-4">
                          <ul className="list-unstyled social-list mb-0">
                            <li className="list-inline-item tooltip-hover">
                              <a href="#" className="rounded"><span className="ti-facebook" /></a>
                              <div className="tooltip-item">Facebook</div>
                            </li>
                            <li className="list-inline-item tooltip-hover"><a href="#" className="rounded"><span className="ti-instagram" /></a>
                              <div className="tooltip-item">Instagram</div>
                            </li>
                            <li className="list-inline-item tooltip-hover"><a href="#" className="rounded"><span className="ti-linkedin" /></a>
                              <div className="tooltip-item">Linkedin</div>
                            </li>
                            <li className="list-inline-item tooltip-hover"><a href="#" className="rounded"><span className="ti-youtube" /></a>
                              <div className="tooltip-item">Youtube</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      </div>
                    <div className="col-md-3 col-sm-6">
                     </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                  <div className="row footer-top-wrap mt-md-4 mt-sm-0 mt-lg-0">
                    <div className="col-12">
                      <div className="footer-nav-wrap text-white">
                        <h4 className="text-white">GET IN TOUCH</h4>
                        <ul className="get-in-touch-list">
                          <li className="d-flex align-items-center py-2"><span className="fas fa-envelope mr-2" /> info@buzinesswriting.com </li>
                          <li className="d-flex align-items-center py-2"><span className="fas fa-envelope mr-2" /> buzinesswriting1@gmail.com
                  </li>
                          </ul>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*footer top end*/}
          {/*footer copyright start*/}
          <div className="footer-bottom gray-light-bg py-2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-5 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">Copyrights © 2020. All
                  rights reserved by
                  <a href="" target="_blank"> Buziness Writing</a></p>
                </div>
                <div className="col-md-7 col-lg-6 d-none d-md-block d-lg-block">
                  <ul className="list-inline policy-nav text-right social-list">
                    <li className="list-inline-item"><a href="#">Privacy Policy</a></li>
                    <li className="list-inline-item"><a href="#">Terms &amp; Conditions</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*footer copyright end*/}
        </footer>
        <button className="scroll-top scroll-to-target" onClick={scrollTop}>
          <span className="ti-angle-up" />
        </button>
        <a href="https://api.whatsapp.com/send?phone=00447429125487&text=Hello Dear .. are you available" class="float" target="_blank">
          {/* <i class="fa fa-whatsapp my-float"></i> */}
          <FontAwesome
        className="my-float"
        name="whatsapp"
      />
        </a>

      </>

    )
  }
}
