import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer'
import Garuntee from '../component/Garuntee'
import Header from '../component/Header'
import Preloader from '../component/Preloader'
import PriceCalculator from '../component/PriceCalculator'
import Steps from '../component/Steps'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'
import newsletter1 from '../images/newsletter1.png';
import newsletter2 from '../images/newsletter2.png';
import newsletter3 from '../images/newsletter3.png';
import newsletter4 from '../images/newsletter4.png';
export default class Newsletter extends Component {
    constructor() {
        super();
        this.state = {
            setIsOpen: false,
            setIsOpen2:false,
            setIsOpen3:false,
            setIsOpen4:false
        }
    }
    openModal() {
        this.setState({ setIsOpen: true })

    }
    openModal02() {
        this.setState({setIsOpen2:true})
    }
    openModal03() {
        this.setState({setIsOpen3:true})
    }
    openModal04() {
        this.setState({setIsOpen4:true})
    }
    closeModal() {
        this.setState({ setIsOpen: false })
        this.setState({ setIsOpen2: false })
        this.setState({ setIsOpen3: false })
        this.setState({ setIsOpen4: false })
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Buziness Writing | Newsletter Writing</title>
            </Helmet>
                {/* <Preloader/> */}
               <Header/>
               {/*header section start*/}
               <section className="hero-section ptb-100 gradient-overlay" style={{ background: 'url("img/header-bg-5.jpg")no-repeat center center / cover' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10">
                                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                    <h1 className="text-white mb-0">GIVE YOUR CUSTOMERS THE DIGITAL INSPIRATION THEY NEED</h1>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn check-btn mt-3">Get Started</a>
                                    <h3 className="text-white mt-3">
                                    Allow us to compose pamphlets that are energizing and simultaneously pleasing, to acquire the correct web traffic.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*header section end*/}

                {/*about section with promo start*/}
                <section className="about-with-promo ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <h2>NEWS LETTERS</h2>
                                    <span className="animate-border mb-4" />
                                    <p>
                                    Writing a successful pamphlet is as interesting as walking on a minefield, losing a wrong word and a user's idea. We help you mobilize your ideas by substituting our own words for you. We have a group of master and talented material designers who have a flair for drawing and charming material supply.
                                    </p>
                                    <p>
                                    Accordingly we present our committed bulletin composing administration where we assist you with composing the point, and drawing in pamphlets that drive the necessary traffic back to your site.
                                    </p>
                                   <ul className="check-list-info">
                                        <li><strong> Every newsletter </strong>  is custom written – specifically for you</li>
                                        <li><strong> Quickest turnaround </strong> time – 2 working days</li>
                                        <li><strong> Error free </strong>final document – reviewed by editors</li>
                                        <li><strong>Customer support  </strong>available online – 24/7</li>
                                        <li><strong> No replication  </strong> of your content – 0% plagiarism</li>
                                        <li><strong> No-limit on revisions  </strong> That too free</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-promo-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/increase.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p >Document - 01</p>
                                                {/* <button >Click Me</button> */}
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal02()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/search-engine.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 02</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal03()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/credit-card.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 03</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal04()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/interface.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 04</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*about section with promo end*/}
               <PriceCalculator/>
                <Garuntee/>
                <Steps/>
                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <img src={newsletter1} style={{width:"100%"}} className="img-responsive"/>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen2}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                             <img src={newsletter2} style={{width:"100%"}} className="img-responsive"/>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpen3}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <img src={newsletter3} style={{width:"100%"}} className="img-responsive"/>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen4}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                             <img src={newsletter4} style={{width:"100%"}} className="img-responsive"/>
                        </div>
                    </div>
                </Modal>
                <Footer/>   
            </>
        )
    }
}
