import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer'
import Garuntee from '../component/Garuntee'
import Header from '../component/Header'
import Preloader from '../component/Preloader'
import PriceCalculator from '../component/PriceCalculator'
import Steps from '../component/Steps'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'
import eb1 from '../images/eb1.png'
import eb2 from '../images/eb2.png'
import eb3 from '../images/eb3.png'
import eb4 from '../images/eb4.png'

export default class Ebook  extends Component {
    constructor() {
        super();
        this.state = {
            setIsOpen: false,
            setIsOpen2:false,
            setIsOpen3:false,
            setIsOpen4:false
        }
    }
    openModal() {
        this.setState({ setIsOpen: true })

    }
    openModal02() {
        this.setState({setIsOpen2:true})
    }
    openModal03() {
        this.setState({setIsOpen3:true})
    }
    openModal04() {
        this.setState({setIsOpen4:true})
    }
    closeModal() {
        this.setState({ setIsOpen: false })
        this.setState({ setIsOpen2: false })
        this.setState({ setIsOpen3: false })
        this.setState({ setIsOpen4: false })
    }
    render() {
    return (
        <>
            <Helmet>
                <title>Buziness Writing | Ebook Writing</title>
            </Helmet>
            {/* <Preloader/> */}
               <Header/>  
                {/*header section start*/}
               <section className="hero-section ptb-100 gradient-overlay" style={{ background: 'url("img/header-bg-5.jpg")no-repeat center center / cover' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10">
                                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                    <h1 className="text-white mb-0">FREE DESIGNING SERVICES WITH OUR COMPOSING EBOOK PACKAGES</h1>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn check-btn mt-3">Get Started</a>
                                    <h3 className="text-white mt-3">
                                    Assisting You With turning into a Best Selling Author or Construct Marketing Material for Your Brands.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*header section end*/}

                {/*about section with promo start*/}
                <section className="about-with-promo ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <h2>EBOOKS</h2>
                                    <span className="animate-border mb-4" />
                                    <p>
                                    With over a time of involvement with secretly composing, altering and distributing eBooks, Buziness Writing is an all in one resource for every one of your prerequisites. Regardless of whether you need to distribute inventive books, kids' books.
                                    </p>
                                    <p>
                                    We have worked for a wide assortment of customers given  are a couple of our best composed works. Go through our composed examples and witness the lucidity and ease with which our accomplished innovative authors offer life to the organization.
                                    </p>
                                  
                                    
                                    <ul className="check-list-info">
                                        <li><strong>Creatively written</strong>content – to grab the reader’s attention </li>
                                        <li><strong>  table of contents</strong> Well-structured together </li>
                                        <li><strong>eBook created </strong>for your specific requirement</li>
                                        <li><strong>Proofread and edited</strong> by our expert editors</li>
                                        <li><strong>100% authentic  </strong> information – originality guaranteed</li>
                                        <li><strong> 2 working days</strong> Quickest possible turnaround time </li>
                                     
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-promo-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/increase.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p >Document - 01</p>
                                                {/* <button >Click Me</button> */}
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal02()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/search-engine.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 02</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal03()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/credit-card.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 03</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal04()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/interface.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 04</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*about section with promo end*/}
               <PriceCalculator/>
                <Garuntee/>
                <Steps/>
                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                        <img src={eb1} style={{width:"100%"}} className="img-responsive"/>

                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen2}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                        <img src={eb2} style={{width:"100%"}} className="img-responsive"/>

                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpen3}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                        <img src={eb3} style={{width:"100%"}} className="img-responsive"/>

                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen4}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                        <img src={eb4} style={{width:"100%"}} className="img-responsive"/>

                        </div>
                    </div>
                </Modal>
                <Footer/>  
        </>
    )
}
}
