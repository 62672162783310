import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer'
import Header from '../component/Header'
import Preloader from '../component/Preloader'
import axios from 'axios'
import swal from 'sweetalert';
export default class Order extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            name:'',
            email:'',
            phone:'',
            text:'',
            
            emailerr:'',
            phoneerr:'',
            texterr:'',
            nameerr:'', 
            
        }
    }
    valid = () =>
    {
        if(this.state.name.length <= 0 && this.state.email.length <= 0 && this.state.phone.length <= 0 && this.state.text.length <= 0)
        {
            this.setState({
                nameerr : 'This field is required',
                emailerr : 'This field is required',
                phoneerr : 'This field is required',
                texterr : 'This field is required',
            })
        }

        if(this.state.name.length <= 0)
        {
                this.setState({nameerr:"This field is required"})
        }
        if(this.state.email.length <= 0)
        {
                this.setState({emailerr:"This field is required"})
        }
        // if(this.state.email.includes("@"))
        // {
        //         this.setState({emailerr:"Email is not valid "})
        // }
        if(this.state.phone.length <= 0)
        {
                this.setState({phoneerr:"This field is required"})
        }
        if(this.state.text.length <= 0)
        {
                this.setState({texterr:"This field is required"})
        }

        else
        {
            return true;
        }
    }

    submitForm = () =>
    {
        this.setState({
            emailerr:'',
            phoneerr:'',
            texterr:'',
            nameerr:'', 
        });
        console.log("",this.valid())
        if(this.valid() == true)
        {
        // alert();

            const requestOptions = {
                // body: JSON.stringify({ 
                
                    email: this.state.email,
                name: this.state.name,
                phone:this.state.phone,
                message:this.state.text
                
                // })
            };
            // fetch('http://emailapi.buzinesswriting.com/api/email/send/', requestOptions)
            //     .then(response => response.json())
            //     .then(data => alert("form submited"));
            axios.post('https://emailapi.buzinesswriting.com/api/email/send', requestOptions)
              .then(function (response) {
                console.log(response);
                if(response.status == 200)
                {
                    swal({
                        title: "Your order request submit successfully .. we will contact you soon",
                        icon: "success",
                      });
                      Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                      );
                      Array.from(document.querySelectorAll("textarea")).forEach(
                        textarea => (textarea.value = "")
                      );
                }
                else{
                    swal({
                        title: "Something went wrong .. please try again",
                        icon: "error",
                      });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
            // axios({
            //     method: 'post',
            //     url: 'https://emailapi.buzinesswriting.com/api/email/send',
            //     data: requestOptions
            //   }).then(function (response) {
            //     console.log(response)
            //   });
        }
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Business Writing | Order </title>
                </Helmet>
                {/* <Preloader/> */}
                <Header />
                <div className="mt-3">
                    {/*contact us section start*/}
                    <section className="contact-us-section ptb-100">
                        <div className="container contact">
                            <div className="col-12 pb-3 message-box d-none">
                                <div className="alert alert-danger" />
                            </div>
                            <div className="row justify-content-around">
                                <div className="col-md-6">
                                    <div className="contact-us-form gray-light-bg rounded p-5">
                                        <h4>Ready to get started?</h4>
                                            <form  className="contact-us-form">
                                                <div className="form-row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" onChange={(e)=>{this.setState({name: e.target.value})}} name="name" placeholder="Enter name" required="true"/>
                                                            <span className="text-danger">{this.state.nameerr}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" onChange={(e)=>{this.setState({email: e.target.value})}} name="email" placeholder="Enter email" required="true"/>
                                                            <span className="text-danger">{this.state.emailerr}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" onChange={(e)=>{this.setState({phone: e.target.value})}} name="phone" placeholder="Enter phone" required="true"/>
                                                            <span className="text-danger">{this.state.phoneerr}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <textarea name="text"  className="form-control" onChange={(e)=>{this.setState({text: e.target.value})}} rows={7} cols={25} placeholder="Message" defaultValue={""} required="true"/>
                                                            <span className="text-danger">{this.state.texterr}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mt-3">
                                                        <button type="button" onClick={this.submitForm} className="btn secondary-solid-btn" id="btnContactUs">
                                                            Send Message
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="contact-us-content">
                                        <h2>Looking for a excellent Business idea?</h2>
                                        <p className="lead">At Buzinesswriting we have industry specialists who compose well-informed and exact proposition to convey your comprehension of the venture and how you will be the most ideal decision if the task is appointed to you. Connect with us to benefit the best proposition composing administrations accessible online for a wide range of undertakings. This will be a choice that should not be taken lightly that you won't lament making.</p>
                                        <hr className="my-5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*contact us section end*/}
                </div>


                <Footer />
            </>
        )
    }
}
