import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer'
import Garuntee from '../component/Garuntee'
import Header from '../component/Header'
import Preloader from '../component/Preloader'
import PriceCalculator from '../component/PriceCalculator'
import Steps from '../component/Steps'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'
import br1 from '../images/br1.jpg'
import br2 from '../images/br2.jpg'
import br3 from '../images/br3.jpg'
import br4 from '../images/br4.jpg'

export default class Reports extends Component {
    constructor() {
        super();
        this.state = {
            setIsOpen: false,
            setIsOpen2:false,
            setIsOpen3:false,
            setIsOpen4:false
        }
    }
    openModal() {
        this.setState({ setIsOpen: true })

    }
    openModal02() {
        this.setState({setIsOpen2:true})
    }
    openModal03() {
        this.setState({setIsOpen3:true})
    }
    openModal04() {
        this.setState({setIsOpen4:true})
    }
    closeModal() {
        this.setState({ setIsOpen: false })
        this.setState({ setIsOpen2: false })
        this.setState({ setIsOpen3: false })
        this.setState({ setIsOpen4: false })
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Buziness Writing | Report</title>
            </Helmet>
              {/* <Preloader/> */}
               <Header/>  
                {/*header section start*/}
               <section className="hero-section ptb-100 gradient-overlay" style={{ background: 'url("img/header-bg-5.jpg")no-repeat center center / cover' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10">
                                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                    <h1 className="text-white mb-0">SPEAK WITH AUTHORITY; CONCRETE, CLEAR AND SIMPLE</h1>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn check-btn mt-3">Get Started</a>
                                    <h3 className="text-white mt-3">
                                    We assist you with business reports in a way that imparts the message without loss of interest.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*header section end*/}

                {/*about section with promo start*/}
                <section className="about-with-promo ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <h2>REPORTS/CASE STUDIES</h2>
                                    <span className="animate-border mb-4" />
                                    <p>
                                    A contextual analysis needs to stay direct, where you talk about case explicit subtleties that were needed by the customer and the procedure or strategy that you as a business attempted to beat those issues, conveying arrangements.
                                    </p>
                                    <p>
                                    Basically, in business information where clear business jokes should be talked about remembering the B2B layout of things, such a message will be considered a key component in translating.
                                    </p>
                                    <ul className="check-list-info">
                                        <li><strong>report/case subject

</strong> Well researched and focused on your </li>
                                        <li><strong>Formatted and structured </strong>as per your order specs</li>
                                      
                                        <li><strong>Quickest turnaround times  </strong> – within stipulated timelines</li>
                                        <li><strong>Error-free content </strong>– Checked by Editors</li>
                                        <li><strong>Keywords based</strong>on your subject area </li>
                                        <li><strong>online support</strong> Round the clock available

</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-promo-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/increase.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p >Document - 01</p>
                                                {/* <button >Click Me</button> */}
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal02()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/search-engine.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 02</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal03()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/credit-card.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 03</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal04()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/interface.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 04</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*about section with promo end*/}
               <PriceCalculator/>
                <Garuntee/>
                <Steps/>
                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                        <img src={br1} style={{width:"100%"}} className="img-responsive"/>

                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen2}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                        <img src={br2} style={{width:"100%"}} className="img-responsive"/>
                            
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpen3}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                        <img src={br3} style={{width:"100%"}} className="img-responsive"/>

                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen4}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                        <img src={br4} style={{width:"100%"}} className="img-responsive"/>

                        </div>
                    </div>
                </Modal>
                <Footer/>  

            </>
        )
    }
}
