import React, { Component } from 'react'

export default class Garuntee extends Component {
    render() {
        return (
            <>
             {/*our work or portfolio section start*/}
             <section className="our-portfolio-section ptb-100">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="section-heading text-center mb-4">
                                        <h2>OUR GUARANTEES</h2>
                                        <span className="animate-border mr-auto ml-auto mb-4" />
                                        <p className="lead">Our Promises that Help Us Deliver the Best Content Writing Services</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="portfolio-container" id="MixItUp">
                                        <div className="mix portfolio-item branding" data-ref="mixitup-target">
                                            <div className="portfolio-wrapper">
                                                <div className="text-center p-4">
                                                    <h5 className="mb-1"><span className="checkmark"></span>Fastest Turn around Times</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mix portfolio-item other animation" data-ref="mixitup-target">
                                            <div className="portfolio-wrapper">
                                                <div className="text-center p-4">
                                                    <h5 className="mb-1"><span className="checkmark"></span> Money Back Guarantee</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mix portfolio-item animation" data-ref="mixitup-target">
                                            <div className="portfolio-wrapper">
                                                
                                                <div className="text-center p-4">
                                                    <h5 className="mb-1"><span className="checkmark"></span> Free Unlimited Revisions</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mix portfolio-item branding" data-ref="mixitup-target">
                                            <div className="portfolio-wrapper">
                                                <div className="text-center p-4">
                                                    <h5 className="mb-1"><span className="checkmark"></span> 24/7 Customer Support</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mix portfolio-item animation other" data-ref="mixitup-target">
                                            <div className="portfolio-wrapper">
                                                <div className="text-center p-4">
                                                    <h5 className="mb-1"><span className="checkmark"></span> Tailored to your needs</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mix portfolio-item branding " data-ref="mixitup-target">
                                            <div className="portfolio-wrapper">
                                                
                                                <div className="text-center p-4">
                                                    <h5 className="mb-1"><span className="checkmark"></span> Highest quality assurance</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gap" />
                                        <div className="gap" />
                                        <div className="gap" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*our work or portfolio section end*/}   
            </>
        )
    }
}
