import React, { Component } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Preloader from '../component/Preloader';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Garuntee from '../component/Garuntee';
import Steps from '../component/Steps';
import { Helmet } from 'react-helmet';
export default class Index extends Component {

    render() {
        return (
            <>
                <Helmet>
                    <title>Buziness Writing | Home</title>
                </Helmet>
                {/* <Preloader /> */}
                <Header />
                <div className="main">
                    {/*hero section start*/}
                    <section className="ptb-70 gradient-bg">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-5">
                                    <div className="hero-slider-content text-white pt-5">
                                        <strong>Buziness Writing</strong>
                                        <h1 className="text-white">THE EPITOME OF CREATIVE COPY WRITING</h1>
                                        <p className="lead">Hire the Web's Best Content Writers With Our Professional Content Writing Services</p>
                                        <div className="action-btns mt-3">
                                            <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn">Get Started</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-7">
                                    <div className="image-wrap pt-5">
                                        <img src="img/t1-hero-img.svg" className="img-fluid custom-width" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*hero section end*/}
                    {/*services section start*/}
                    <section className="services-section ptb-100 gray-light-bg">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <strong className="color-secondary">Our Services</strong>
                                        <h2>What Can We Do For You</h2>
                                        <span className="animate-border mr-auto ml-auto mb-4" />
                                        <p className="lead"> Let Us Write Superior Quality Content For You </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="services-single text-center p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                                        <span className="ti-announcement icon-lg color-secondary d-block mb-4" />
                                        <h5>Competitor’s Analysis</h5>
                                        <p className="mb-0">
                                            We lead an intensive review of your rivals' to figure devise your substance system. This assists us with utilizing the experiences and create content that is superior to your opposition.
                                       </p>
                                        {/* <a href="services-details.html" target="_blank" className="detail-link mt-4">Read more <span className="ti-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="services-single text-center p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                                        <span className="ti-light-bulb icon-lg color-secondary d-block mb-4" />
                                        <h5>Know your Customer</h5>
                                        <p className="mb-0">
                                            Get an expansion in your ROI by understanding what bids to your clients and what puts them off. A substance procedure that clicks with your clients wins you a lot of income.
                                        </p>
                                        {/* <a href="services-details.html" target="_blank" className="detail-link mt-4">Read more <span className="ti-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="services-single text-center p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                                        <span className="ti-headphone-alt icon-lg color-secondary d-block mb-4" />
                                        <h5>Check And Recheck</h5>
                                        <p className="mb-0">
                                            Get an expansion in your ROI by understanding what bids to your clients and what puts them off. A substance procedure that clicks with your clients wins you a lot of income.
                                        </p>
                                        {/* <a href="services-details.html" target="_blank" className="detail-link mt-4">Read more <span className="ti-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="services-single text-center p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                                        <span className="ti-bell icon-lg color-secondary d-block mb-4" />
                                        <h5>AUDIENCE ANALYSIS</h5>
                                        <p className="mb-0">
                                            Apply your client's information on to your substance, content that gets seen by your client's is what sells. We ensure that your substance is open and requests to the ideal individuals.
                                        </p>
                                        {/* <a href="services-details.html" target="_blank" className="detail-link mt-4">Read more <span className="ti-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="services-single text-center p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                                        <span className="ti-briefcase icon-lg color-secondary d-block mb-4" />
                                        <h5>SOCIAL GAUGING</h5>
                                        <p className="mb-0">
                                            Listen effectively and partake with your clients on the social stages. This assists you with bettering comprehend your clients and tailor fit substance that will undoubtedly offer and produce returns.
                                        </p>
                                        {/* <a href="services-details.html" target="_blank" className="detail-link mt-4">Read more <span className="ti-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="services-single text-center p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                                        <span className="ti-vector icon-lg color-secondary d-block mb-4" />
                                        <h5>RESULT ORIENTED STRATEGY</h5>
                                        <p className="mb-0">
                                            With an into the great beyond vision of what kind of substance, creation time and generally speaking association objectives to be met with its turn of events, you are on the way to progress with your substance technique.
                                        </p>
                                        {/* <a href="services-details.html" target="_blank" className="detail-link mt-4">Read more <span className="ti-arrow-right" /></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*services section end*/}
                    {/*about us section start*/}
                    <section className="about-us-section ptb-100 gray-light-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="about-us-img">
                                        <img src="img/about-us-img.svg" alt="about us" className="img-fluid about-single-img" />
                                        <img src="img/about-us-img-bg.svg" alt="about bg" className="about-us-bg" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="about-us-content-wrap">
                                        <strong className="color-secondary">About Us</strong>
                                        <h3>WELCOME TO Buziness Writing</h3>
                                        <span className="animate-border mb-4" />
                                        {/* That’s where we come in.  */}
                                        <p>The Buziness Writting marketplace is designed to create high quality content at any scale. Whether you’re a Fortune 500 retailer looking to re-write and enhance 1 million product descriptions, or a small business adding a few pages of content to your new website, we’ll help you get the job done.</p>
                                        <ul className="list-unstyled tech-feature-list">
                                            <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>Competitor’s</strong>
                                                Analysis
                                            </li>
                                            <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>Know your</strong>
                                                Know your Customer
                                            </li>
                                            <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>Check</strong>
                                                And Recheck
                                            </li>
                                            <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>Audience</strong>
                                                Analysis
                                            </li>
                                            <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>Social</strong>
                                                Gauging
                                            </li>
                                            <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>Result Oriented</strong>
                                                Strategy
                                            </li>
                                            {/* <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>45-Day</strong>
                Money-Back Guarantee
              </li> */}
                                        </ul>

                                        {/* <div className="row mt-4">
                                            <div className="col-4">
                                                <div className="counter-single">
                                                    <h2 className="mb-0 color-secondary">4566</h2>
                                                    <strong>Total Project</strong>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="counter-single">
                                                    <h2 className="mb-0 color-secondary">25002</h2>
                                                    <strong>Total Customer</strong>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="counter-single">
                                                    <h2 className="mb-0 color-secondary">4566</h2>
                                                    <strong>Total Project</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="action-btns mt-4">
                                            <a href="#" className="btn secondary-solid-btn mr-3">Start Now</a>
                                            <a href="#" className="btn secondary-outline-btn">Learn More</a>
                                        </div>
                                     */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*about us section end*/}
                    <Steps />
                    {/*call to action section start*/}
                    <section className="call-to-action-video">
                        <div className="row m-0">
                            <div className="col-lg-6 col-md-12 p-0">
                                <div className="free-trial-image text-center">
                                    <img src="img/cta-2-girl-img.jpg" alt="image" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 p-0">
                                <div className="free-trial-content text-white">
                                    <h2 className="text-white">At buzinesswriting, we are a gathering of profoundly talented.</h2>
                                    <p>The business' generally connecting with content advancement administrations.</p>
                                    <a href="#" className="btn primary-solid-btn mt-3">Order Now</a>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn primary-solid-btn mt-3 ml-3">Talk to us</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*call to action section end*/}
                    {/*outstanding section start*/}
                    <section className="outstanding-section ptb-100 gray-light-bg">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-9 col-lg-8">
                                    <div className="section-heading text-center mb-1">
                                        <strong className="color-secondary">Our Expertise</strong>
                                        <h2>Outstanding Experience</h2>
                                        <span className="animate-border mr-auto ml-auto mb-4" />
                                        <p className="lead">Buziness Writting lets you get all your written content created in one central location.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <div className="feature-tabs-wrap">
                                        <ul className="nav nav-tabs border-bottom-0 feature-tabs feature-tabs-center d-flex justify-content-center" data-tabs="tabs">
                                            <li className="nav-item">
                                                <a className="nav-link text-center" href="#feature-tab-1" data-toggle="tab">
                                                    <span className="ti-announcement icon-sm" />
                                                    <h6 className="mb-0">Blog & Articles</h6>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-center" href="#feature-tab-2" data-toggle="tab">
                                                    <span className="ti-light-bulb icon-sm" />
                                                    <h6 className="mb-0">SEO Services</h6>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-center active" href="#feature-tab-3" data-toggle="tab">
                                                    <span className="ti-email icon-sm" />
                                                    <h6 className="mb-0">Resume Writing</h6>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-center" href="#feature-tab-4" data-toggle="tab">
                                                    <span className="ti-shield icon-sm" />
                                                    <h6 className="mb-0">Press Release</h6>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-center" href="#feature-tab-5" data-toggle="tab">
                                                    <span className="ti-vector icon-sm" />
                                                    <h6 className="mb-0">Business Writing</h6>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content feature-tab-content">
                                            <div className="tab-pane mt-5" id="feature-tab-1">
                                                <div className="row justify-content-around">
                                                    <div className="col-md-12 col-lg-5">
                                                        <div className="about-content-right mb-md-4 mb-lg-0 my-md-3 my-lg-3 my-sm-0">
                                                            <p>
                                                                Plan your entire content calendar with Campaigns and watch as your blog post orders are automatically placed and written based on the schedule you’ve created.
                                                            </p>
                                                            <p>
                                                                Easily favorite writers you love to build a content writing team for your blog. Our blog writers are experts at following brand guidelines and maintaining a consistent voice.
                                                            </p>
                                                            <ul className="list-unstyled tech-feature-list">
                                                                <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>Corporate </strong>
                          Cash Management
                        </li>
                                                                <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>SEO </strong>
                          Optimization Services
                        </li>
                                                                <li className="py-1"><span className="ti-control-forward mr-2 color-secondary" /><strong>Company </strong>
                          Brand Solutions
                        </li>
                                                            </ul>
                                                            <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="mt-4 btn secondary-solid-btn">Contact With Us</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-lg-6">
                                                        <div className="img-wrap my-md-3 my-lg-3 my-sm-0">
                                                            <img src="img/about-1.jpg" alt="about" className="img-fluid rounded shadow-sm" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane mt-5" id="feature-tab-2">
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-md-6 col-lg-5">
                                                        <div className="why-choose-us-wrap">
                                                            <h3>Best Consulting Every For Business</h3>
                                                            <p>Search engine optimization (SEO) experts agree that high-quality content can take your website to the top of the search results. This is possible because Google rewards websites that publish high-quality content frequently and regularly.</p>
                                                            <ul className="list-with-icon">
                                                                <li className="d-flex align-items-start">
                                                                    <img src="img/increase.svg" width={35} className="mr-3" alt="list icon" />
                                                                    <div className="list-icon-info">
                                                                        <strong>Social Optimized</strong>
                                                                        <p>SEO without social media no longer exists. We live in a completely integrated marketing world.</p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex align-items-start">
                                                                    <img src="img/search-engine.svg" width={35} className="mr-3" alt="list icon" />
                                                                    <div className="list-icon-info">
                                                                        <strong>Delight Visitors</strong>
                                                                        <p>SEO campaigns succeed when your content is focused on two audiences – search engine is human readers.</p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex align-items-start">
                                                                    <img src="img/interface.svg" width={35} className="mr-3" alt="list icon" />
                                                                    <div className="list-icon-info">
                                                                        <strong>Build Links</strong>
                                                                        <p>You need high quality, authoritative sites linking back to your website in order to drive your search top rankings.</p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6">
                                                        <div className="image-wrap">
                                                            <img src="img/t6-hero-img1.svg" alt="why choose us" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane active mt-5" id="feature-tab-3">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="image-wrap">
                                                            <img src="img/tab-img.png" alt="email" className="img-fluid m-auto" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="content-wrap">
                                                            <h3>Grow Your Business With Experienced SEO Experts</h3>
                                                            <p>
                                                                You need an article writing company with a diverse workforce, highly professional writers and a workflow that makes it easy to order and receive content. Our platform makes it easy to place orders or work with project management teams for strategic direction.
                                                            </p>
                                                            <p>
                                                                Articles published on your site or that link back to your pages can provide valuable SEO benefits — if the quality is good enough. Google and other search engines don’t have time for sub-par content, and neither do you.
                                                            </p>
                                                            <div className="action-btns mt-4">
                                                                <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn mr-3">Start Now</a>
                                                                <a href="#" className="btn secondary-outline-btn">Learn More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane mt-5" id="feature-tab-4">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="content-wrap">
                                                            <h3>Grow Your Business With Experienced SEO Experts</h3>
                                                            <p>People care about companies that are different. How are you different from your competitors? Showcase what makes you unique and, ultimately, better by telling your story through a press release. Our proven writers understand how to present your company in the best light possible.</p>
                                                            <p>
                                                                Tell the public how you’re helping people. Financial milestones can be interesting, but, in the end, we’re all human beings with an emotional side to us.
                                                            </p>
                                                            <div className="action-btns mt-4">
                                                                <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn mr-3">Start Now</a>
                                                                <a href="#" className="btn secondary-outline-btn">Learn More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="image-wrap">
                                                            <img src="img/tab-img.png" alt="email" className="img-fluid m-auto" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane mt-5" id="feature-tab-5">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 white-bg shadow-sm rounded">
                                                            <span className="ti-announcement icon-lg color-primary d-block mb-4" />
                                                            <h5>Executive Summaries</h5>
                                                            <p className="mb-0">Executive summaries are another important business documents that are considered vital for major business related decisions.</p>
                                                            <a href="services-details.html" target="_blank" className="detail-link mt-4">Read
                        more <span className="ti-arrow-right" /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 white-bg shadow-sm rounded">
                                                            <span className="ti-light-bulb icon-lg color-primary d-block mb-4" />
                                                            <h5>Business Plan</h5>
                                                            <p className="mb-0">Administration that each business person needs to establish a long term connection with their financial backers and partners.</p>
                                                            <a href="services-details.html" target="_blank" className="detail-link mt-4">Read
                        more <span className="ti-arrow-right" /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 white-bg shadow-sm rounded">
                                                            <span className="ti-headphone-alt icon-lg color-primary d-block mb-4" />
                                                            <h5>Report/Case Studies</h5>
                                                            <p className="mb-0">A case study has to remain to the point, where you discuss case specific details that were required by the client.</p>
                                                            <a href="services-details.html" target="_blank" className="detail-link mt-4">Read
                        more <span className="ti-arrow-right" /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 white-bg shadow-sm rounded">
                                                            <span className="ti-bell icon-lg color-primary d-block mb-4" />
                                                            <h5>Presentation PPT</h5>
                                                            <p className="mb-0">Introductions are a simple and powerful approach to show your data in graphical or insignificantly phrased slides.</p>
                                                            <a href="services-details.html" target="_blank" className="detail-link mt-4">Read
                        more <span className="ti-arrow-right" /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 white-bg shadow-sm rounded">
                                                            <span className="ti-briefcase icon-lg color-primary d-block mb-4" />
                                                            <h5>Proposals</h5>
                                                            <p className="mb-0">A proposal is the first step towards your project accomplishments, and like everyone else, your first step perfect and in the right direction.</p>
                                                            <a href="services-details.html" target="_blank" className="detail-link mt-4">Read
                        more <span className="ti-arrow-right" /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 white-bg shadow-sm rounded">
                                                            <span className="ti-vector icon-lg color-primary d-block mb-4" />
                                                            <h5>Corporate Profile</h5>
                                                            <p className="mb-0">Corporate profiles help structure the initial feelings for your business, this record ought to be elegantly composed with a sharp spotlight.</p>
                                                            <a href="services-details.html" target="_blank" className="detail-link mt-4">Read
                        more <span className="ti-arrow-right" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*outstanding section end*/}
                    <Garuntee />
                    {/*testimonial section start*/}
                    <section className="testimonial-section ptb-100" style={{ background: 'url("img/testimonial-bg.png")no-repeat center center / cover' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-lg-5">
                                    <div className="testimonial-heading text-white">
                                        <h2 className="text-white">What Our Client Say About Us</h2>
                                        <span className="animate-border mb-4" />
                                        <p>A portion of Our Best Reviews from Our Clients</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="testimonial-content-wrap">
                                        <img src="img/testimonial-arrow-top.png" className="img-fluid testimonial-tb-shape shape-top" alt="testimonial shape" />
                                        <OwlCarousel loop items={1} className="owl-carousel owl-theme client-testimonial-1 custom-dot testimonial-shape">
                                            <div className="item">
                                                <div className="testimonial-quote-wrap">
                                                    <div className="media author-info mb-3">
                                                        <div className="author-img mr-3">
                                                            <img src="img/client-1.jpg" alt="client" className="img-fluid" />
                                                        </div>
                                                        <div className="media-body text-white">
                                                            <h5 className="mb-0 text-white">John Charles</h5>
                                                            <span>BizBite</span>
                                                        </div>
                                                        <span className="fas fa-quote-right icon-md text-white" />
                                                    </div>
                                                    <div className="client-say text-white">
                                                        <p>
                                                        Being in the business for customer overhauling we need to grow an excess of substance excessively fast, Buzinesswriting has consistently been there to convey their administrations and has consistently prevailed upon us. Much thanks to you Buzinesswriting for your persevering administrations.
                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="testimonial-quote-wrap">
                                                    <div className="media author-info mb-3">
                                                        <div className="author-img mr-3">
                                                            <img src="img/client-2.jpg" alt="client" className="img-fluid" />
                                                        </div>
                                                        <div className="media-body text-white">
                                                            <h5 className="mb-0 text-white">Arabella Ora</h5>
                                                            <span>BizBite</span>
                                                        </div>
                                                        <span className="fas fa-quote-right icon-md text-white" />
                                                    </div>
                                                    <div className="client-say text-white">
                                                        <p>They truly comprehended what I needed as far as my web content system. Their utilization of straightforward yet convincing English left me pleased profoundly. Caps off to the innovative delivering their administrations at Buzinesswriting.com</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="testimonial-quote-wrap">
                                                    <div className="media author-info mb-3">
                                                        <div className="author-img mr-3">
                                                            <img src="img/client-3.jpg" alt="client" className="img-fluid" />
                                                        </div>
                                                        <div className="media-body text-white">
                                                            <h5 className="mb-0 text-white">Jeremy Jere</h5>
                                                            <span>BizBite</span>
                                                        </div>
                                                        <span className="fas fa-quote-right icon-md text-white" />
                                                    </div>
                                                    <div className="client-say text-white">
                                                        <p>Working with Buzinesswriting was a joy, we're very time the board zeroed in thus getting speedy reactions with top-class content created has been an incredible encounter. Anticipating a commonly helpful connection with them.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="testimonial-quote-wrap">
                                                    <div className="media author-info mb-3">
                                                        <div className="author-img mr-3">
                                                            <img src="img/client-4.jpg" alt="client" className="img-fluid" />
                                                        </div>
                                                        <div className="media-body text-white">
                                                            <h5 className="mb-0 text-white">John Charles</h5>
                                                            <span>BizBite</span>
                                                        </div>
                                                        <span className="fas fa-quote-right icon-md text-white" />
                                                    </div>
                                                    <div className="client-say text-white">
                                                        <p>Consistent Content doesn't distribute client tributes, yet as per the site, "Quality is vital and greatness is ensured. Consistent Content doesn't bargain in content that is normal or shoddy. We just sell the greatest, most unique substance."</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </OwlCarousel >

                                        <img src="img/testimonial-arrow-bottom.png" className="img-fluid testimonial-tb-shape shape-bottom" alt="testimonial shape" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*testimonial section end*/}
                    {/*client section start*/}
                    <div className="client-section ptb-100" style={{ background: 'url("img/client-bg.jpg")no-repeat center center / cover' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="client-heading-wrap">
                                        <h3>Who are Happy With Services and Work</h3>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="client-para">
                                        <p>Tragically, to prevail in business, associations need to settle on troublesome decisions constantly - what to do and, more significant, what not to do. The reality of the situation is that at whatever point we settle on a troublesome decision, a few group will win and some will lose. The champs will be cheerful and the washouts despondent. It's difficult to fulfill everyone constantly. In the event that everyone in your association is glad, that might be on the grounds that you're neglecting to lead them </p>
                                        {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo sint ratione, eligendi amet doloribus qui culpa odio atque consequuntur harum ducimus. Sed placeat repudiandae reiciendis explicabo iste odit distinctio quisquam. </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*client section end*/}

                    {/*call to action section start*/}
                    <section className="call-to-action py-5 gray-light-bg">
                        <div className="container">
                            <div className="row justify-content-around align-items-center">
                                <div className="col-md-12">
                                    <div className="subscribe-content text-center">
                                        <h3 className="mb-1">Get High Quality Written Content, For Your Target Audience</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*contact us promo start*/}
                        <section className="contact-us-promo pt-100">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="card single-promo-card single-promo-hover text-center shadow-sm">
                                            <div className="card-body py-5">
                                                <div className="pb-2">
                                                    <span className="ti-mobile icon-sm color-secondary" />
                                                </div>
                                                <div><h5 className="mb-0">Call Us</h5>
                                                    <p className="text-muted mb-0">+44 742 912 5487</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="card single-promo-card single-promo-hover text-center shadow-sm">
                                            <div className="card-body py-5">
                                                <div className="pb-2">
                                                    <span className="ti-email icon-sm color-secondary" />
                                                </div>
                                                <div><h5 className="mb-0">Mail Us</h5>
                                                    <p className="text-muted mb-0">info@buzinesswriting.com</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="card single-promo-card single-promo-hover text-center shadow-sm">
                                            <div className="card-body py-5">
                                                <div className="pb-2">
                                                    <span className="ti-headphone-alt icon-sm color-secondary" />
                                                </div>
                                                <div style={{ cursor: "pointer" }} onClick={() => window.Tawk_API.toggle()}><h5 className="mb-0">Live Chat</h5>
                                                    <p className="text-muted mb-0">Chat with Us 24/7</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*contact us promo end*/}

                    </section>
                    {/*call to action section end*/}

                </div>


                <Footer />

            </>
        )
    }
}
