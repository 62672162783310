import React, { Component } from 'react'
import Footer from '../component/Footer'
import Garuntee from '../component/Garuntee'
import Header from '../component/Header'
import Preloader from '../component/Preloader'
import PriceCalculator from '../component/PriceCalculator'
import Steps from '../component/Steps'
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'
export default class Articles extends Component {
    constructor() {
        super();
        this.state = {
            setIsOpen: false,
            setIsOpen2:false,
            setIsOpen3:false,
            setIsOpen4:false
        }
    }
    openModal() {
        this.setState({ setIsOpen: true })

    }
    openModal02() {
        this.setState({setIsOpen2:true})
    }
    openModal03() {
        this.setState({setIsOpen3:true})
    }
    openModal04() {
        this.setState({setIsOpen4:true})
    }
    closeModal() {
        this.setState({ setIsOpen: false })
        this.setState({ setIsOpen2: false })
        this.setState({ setIsOpen3: false })
        this.setState({ setIsOpen4: false })
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Buziness Writing | Articles</title>
                </Helmet>
                {/* <Preloader/> */}
                <Header />
                {/*header section start*/}
                <section className="hero-section ptb-100 gradient-overlay" style={{ background: 'url("img/header-bg-5.jpg")no-repeat center center / cover' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-10">
                                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                    <h1 className="text-white mb-0">DISTRIBUTE INTERESTING AND IMAGINATIVE ARTICLES</h1>
                                    <a href="javascript:void()" onClick={() => window.Tawk_API.toggle()} className="btn secondary-solid-btn check-btn mt-3">Get Started</a>
                                    <h3 className="text-white mt-3">
                                    Our authors are specialists recorded as a hard copy enamoring articles 
that will undoubtedly become a web.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*header section end*/}

                {/*about section with promo start*/}
                <section className="about-with-promo ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <h2>ARTICLES</h2>
                                    <span className="animate-border mb-4" />
                                    <p>
                                    Probably the least demanding approaches to showcase your contributions on the web are to expound on them either on websites, visitor online journals or inside industry watchwords focused on article posts. Being important for the online business we have plentiful information.
                                    </p>
                                    <p>
                                    you don’t need to be a rocket scientist to do the math. your articles are, the more engagement they will generate. Which eventually means that more and more customers will head to your business to connect with your brand and become a source of revenue.
                                    </p>
                                    <ul className="check-list-info">
                                        <li><strong> Focused on your order </strong> details – tailored fit</li>
                                        <li><strong> Turnaround time as </strong> specified – Wont ask for extensions</li>
                                        <li><strong> Different tone for online </strong>and offline mediums</li>
                                        <li><strong> Error free articles  </strong>– reviewed by our editors</li>
                                        <li><strong> Turnaround time </strong> as specified – Wont ask for extensions</li>
                                        <li><strong> All rights to ownership </strong> reserved – Your content is your property</li>
                                        <li><strong> Zero tolerance </strong> for plagiarism – 0% guaranteed</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-promo-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/increase.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p >Document - 01</p>
                                                {/* <button >Click Me</button> */}
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal02()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/search-engine.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 02</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 promo-col">
                                            <div onClick={(e) => this.openModal03()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/credit-card.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 03</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                            <div onClick={(e) => this.openModal04()} className="promo-item">
                                                <div className="promo-img">
                                                    <img src="img/interface.svg" alt="services" width={60} />
                                                </div>
                                                <h5>Sample</h5>
                                                <p>Document - 04</p>
                                                <div className="promo-bottom-shape">
                                                    <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*about section with promo end*/}
                <PriceCalculator />
                <Garuntee />
                <Steps />
                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Literature Analysis of Zoom by Robert Munsch </h2>
                            <p>
                                Every person has different perceptions about disability, some might portray it just as another adversity in people, while the others might make it look like disable people can make a fortune. This study is a literature analysis of the book – Zoom, written by Robert Munsch who is an all-time favorite children’s author. He writes books mostly on the children he meets or sees while he goes for storytelling in different places. They are the children who then later on transform into characters for his new books. These children are mostly who beat the stereotypes by their full of life, charming and kind of ways that are obstinate.
                            </p>
                            <p>
                                <b>About the Story</b><br></br>
                             The story tells the readers how one can be disable yet free to do according to personal will. One can certainly do anything while being disable. In this book the author tells how the girl is disable and uses a wheelchair to move from one place to another. Lauretta, the girl on the wheelchair insists her parents to buy her the 92-speed, silver, red, and black dirt-bike wheelchair (Mackelprang et al., 2016). She uses and exceeds a certain speed limit, due to which she gets a ticket. After this incident her parents tell her to return the wheelchair and get one with a nominal speed (Pennell et al., 2018). Soon after that, an accident takes place with her elder brother and so she uses the ultra-power of the wheelchair to save her brother’s life by taking him to the hospital.
                            </p>
                            <p>
                                <b>Portraying Disability to Children Aged between 3-5 through- Zoom</b><br></br>
                                Disable people all around the world are considered to be special, are looked at differently and are usually treated as a separate group. While children grow up with some disability, they are aware that they are not normal as they see other children around them being perfectly normal (Lewison et al., 2017). They feel that there is something missing in them and understand that they need to be catered in the best way possible. Children are mostly influenced through books and their illustrations which they go through with their teachers and parents. Books like- Zoom assist in uplifting their morality along with raising their standards. Books portraying disability don’t always have to show the pity on the special children (Howe et al., 2015). Zoom is a manifestation of a disable girl which signifies that the girl is not to be pitied on as she loves to travel on her wheelchair as her support.
Children aging from 3 to 5 years may not understand the literal meaning of disability, however, there are certain ways of letting them know about it. Books, particularly those with illustrations are written specifically to make them understand what disability actually is (Pennell et al., 2018). In his book – Zoom, Robert has provided evidence that all children perceive disability differently.
The book is a regular reminder that disability is not a weakness but a power within itself. Children should be takes to support centres where they see children around them who are just like them, interacting with such people would assure them that everyone has something or the other missing in life. Talking about themselves will make them walk through their fears with disability (Mackelprang et al., 2016). Robert Munsch writes in this book making sure that Lauretta never defies her disability but tries different ways of making it portray in the best way possible.
The author, Robert Munsch makes sure that children read this novel and be motivated about themselves, the one who are handicapped and even the ones who aren’t. After reading this book, normal children were fascinated at the thought that even after having a disability, one can act normal as such people have different ways to make their lifestyle better.

Message from the Book
	 Robert Munsch, the author of this story has hidden an extremely important message behind his humorous words. He himself wrote this book as per request to a girl on the wheelchair. He has depicted how Lauretta, the main character never lets her moral down due to she being on the wheelchair, instead she is excited about getting a new wheelchair and tries to get the best model so that she can enjoy herself in her way (Mackelprang et al., 2016). In the story we are told that Lauretta is not a normal child, but we are shown the fact that however she is, she is normal in her own way.
She never considers herself to be one of the special children who has to be pitied on. Moreover, she tries to do extra ordinary just by sitting in her wheelchair. She races fast and ends up getting a ticket which depicts the fact that she is more dominated over on the key to have fun rather than showing people that she’s having a hard time in her life (Lewison et al., 2017). Laurette even rushes her brother to the hospital after an accident and considers her wheelchair helpful in that case. She believes that her disability is her capability to do more.




Conclusion
	As Robert Muncsh is an author who picks real life characters, he is also the one who writes stories that are inter-related to life of those children. Zoom was another one of his picks on a girl who requested him to write a story on a character on a wheelchair. Later on it became a big hit as the author had shown how a disable girl never fails to make herself happy and helps out people amongst her in time of need. Children who read this book were inspired by her, which also gave a boost to their morals.

                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen2}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>What does it mean to be Omnipresent? </h2>
                            <p>
                            In the world of the dictionary, the Omnipresent is believed to be the one who acquires presence everywhere, every single time or who leaves his effect wherever he goes.  How can a lawyer be omnipresent in their potential buyers mind? To accomplish this task it is better to know your potential buyers carefully. Potential buyers are people who have benefits or reasons to buy a product, such buyers aren’t always regular because they purchase a product just in need. One cannot always possibly make a potential buyer to be their official actual buyer; therefore strategies have to be played. So, one has to be omnipresent in their potential buyers’ mind to ensure that they have more concrete buyers. 
In legal firms being omnipresent is much easier nowadays, this easily helps to catch the exact clients the attorney wants. We can say without any doubt that while dealing with their law firm, they have a colossal advantage of being omnipresent through the information sources and media technology.  We can see legal advertisement everywhere, whether it's on the front page of any magazine, television advertisements shown between programs, huge billboards on the roadside or even newspapers. 
To become omnipresent in such a world of technology, one has to run to the clients they think, will increase their hiring rate. Usually, for most of the law industries, being omnipresent is very high priced, while if chosen the sensible path, the smaller companies or organizations can be omnipresent by knowing the fact that how most of their buyers obtain and evaluate the information. If you go through surveys of your clients, you can spot their trends easily. Furthermore, the client should be asked how they usually evaluate the attorneys' potential, what qualities, standards and ratings customers usually scan through or on what criteria do they compare an attorney to one another. While gathering all of the information regarding the basic questions, the attorney should know where there would be high requirement for their possessed qualities.
The law firm should find more and more about what requirements the clients have, through which platform they would be hired the most. Moreover, clients can be narrowed down through the surveys and that is when the law firm can show omnipresence. To be omnipresent in the eyes of the client, make sure one knows what tools of social networking do the buyers use? What association do they belong to? What kind of blogs are read by them? If the attorney achieves the answers of all these questions it is likely for them to have huge groups of potentially like-minded clients. Therefore, to achieve omnipresence, the attorney should keep the mediums of contact and messages under their control, which leads to more marketing and more potential buyers who are attracted to the attorney.
Whenever a potential buyer visits a law firms’ website, the attorney can become omnipresent to them through effective retargeting. The fact states that 97% of people surf a website for the first time, and before buying anything they leave, and so they are lost forever until or unless you can bring them back. To bring them back, retargeting is done. Once a client visits the attorneys’ website, they will start seeing the ads of that particular website on any other website you visit, whether to read an article, play a game or listen to music, which will eventually remind the client about the website and so they would go through the website again and hire the attorney which matches their need and description from the same website they left without finalizing their attorney.   
Effective retargeting strategies should be planned, the client should be remembered of the website and the product by showing ads on the pages they go to, yet in such a 
particular method that such people don't get fed up or irritated because of the ad. Contributing to the retargeting strategy, when a client visits your website, you should be able to drop a pixel on their website browser, so they can be identified anywhere they go on the internet, your ad can then be shown on the same website they are lurking on. If the customers by chance go to your page and do not finish requesting a product, retargeting on different websites can easily remind them that they have to finish dealing with their attorney. 
Retargeting works effectively as interested people, just those people are being retargeted. This is the easy way to show ones' omnipresence in the world of marketing

                            </p>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpen3}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Historical Significance of Hellenism </h2>
                            <p>
                            Hellenism is known to be the culture of the Greeks, which practically started when the period of Alexander the great ended. When the Hellenistic period started the Macedonians had started controlling the territory and played a great role in reviving the Greek culture. Their politics, art, law, and literature got such applause that it also set its roots in Africa, Asia, and Europe and soon it the Greek culture was also seen emerging in these areas. Not just in such specific areas, but when Hellenization took place many introductions of vast architecture were given, furthermore the language and the life spent of the Greeks were also molded in a more sophisticated manner. Hellenization was soon spread over a large area and thus could be seen from Greece to India, even further to Magnolia and soon it was seen to the farthest states that crossed the Oecumena’s Frontiers. Not only was there a spread of Hellenization across the world, but also modern temples, theaters, and schools as a result of the Greek culture being exported. As time passed by, there came a time when there was a change in the point of view in religion, due to which the Greek gods influence became reduced.  

                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.setIsOpen4}
                    onRequestClose={(e) => this.closeModal()}
                    contentLabel="Example Modal"
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn secondary-solid-btn check-btn" onClick={(e) => this.closeModal()}>
                                <FontAwesome name="times" />
                            </button>
                        </div>
                        <div className="col-md-12 mt-2 content">
                            <h2>Automated Accounting and Financial Reporting Through Brio360 for Business Strategies </h2>
                            <p>
                            The field of business has been outsourcing all in this world of competition, where every business has tried making more and more money, also trying to broaden their aspect of a business. While businessmen try to update their accounting system to strengthen the backbone of their firm, it is because they require accurate checking of accounts and financial reporting. 
Certain companies have hired professionals to keep a check on the company’s daily accounts, but no matter how professional a person is, there is still a chance of human errors. Human error is not just a mistake but it is a fact that is meant to exist. So to eliminate that room for any error the technology has advanced to Automated Accounting and Financial Reporting. 
About an automated accounting system, it is able to collect, store and process any financial entry or data that is fed in by the people running the business. Furthermore, it helps to automate the process of information that requires a lot of time and effort to compile. Talking about financial reporting helps to get the financial results for the external stakeholders and the management, for example by preparing a balance sheet, an income statement, a cash flow statement, etc. 
This might seem a lot of work which would access to an extent of expenses but that’s not the case, we bring you Brio360. Brio is a firm that helps certain business corporations attain the value of the stakeholder and offers advisory. This consulting firm is designed in such a way that it is able to help the firms in need of financial and accounting reporting, which may include Accounting Advisory, Transaction Services, and Operational Improvements. 360 has consultants that work in a way that has a clear head about the clients and what they might expect. This firm also helps in deploying the automated system tools.
Our consultants have been trained in such a manner that they are able to work with the CFO office so that scenarios of the business case can increase and also the analytical capacity. The main focus of our firm is to step up the efficiency and balance out the secular approach of any short-term or long-term goals. While in the meantime Brio360 is keen to accelerate budget and forecast process improvement, investment case development, zero-based budgeting, 13-week cash flow forecast, working capital improvement, strategic planning, EBITDA enhancement, and KPI development, benchmarking & competitive analysis.

The strategy of Brio360 is to help out clients through guidance given out by specialized people. 
Thus all-inclusive solutions are created according to the needs of when the client firm wants. Brio360's specialized team is meant to work along with the firm of the client by helping them optimize systems, streamline processes and by ensuring compliances. By helping the required firm, we are also able to make the audit function better in the whole process.

These are some of the main features specialized by Brio360, but there is so much more. In short, it has helped the whole business industry through its 
consultation and support that some stances have actually started flourishing.
                            </p>
                        </div>
                    </div>
                </Modal>

                <Footer />
            </>
        )
    }
}
